import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "App";
import AppProviders from "context";
import React from "react";
import ReactDOM from "react-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import reportWebVitals from "reportWebVitals";
import ConfigMissing from "shared/config-missing";

const isConfigurationGood =
  process.env.REACT_APP_ENV &&
  process.env.REACT_APP_ACCOUNTS_ENDPOINT &&
  process.env.REACT_APP_BASE_API_URL &&
  process.env.REACT_APP_API_URL;

const sentryEnvs = ["prod", "qa"];

if (sentryEnvs.indexOf(process.env.REACT_APP_ENV!) >= 0) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.5,
    ignoreErrors: [
      "ResizeObserver loop completed with undelivered notifications.",
      "post is not a function",
    ],
  });
}

ReactDOM.render(
  <React.StrictMode>
    {isConfigurationGood ? (
      <AppProviders>
        <App />
        {process.env.REACT_APP_ENV === "local" && (
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        )}
      </AppProviders>
    ) : (
      <ConfigMissing />
    )}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
