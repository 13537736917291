import PropTypes from 'prop-types';
import React from 'react';

import { Popconfirm } from '../../index';
import { WarningOutlined } from '../icons';

export const ConfirmPopup = (props: any) => {

  return (
    <Popconfirm
      placement={props.placement}
      title={props.title || 'This action cannot be undone. Continue?'}
      icon={<WarningOutlined style={{ fontSize: '19px', top: '7px', color: '#E99921' }} />}
      onConfirm={props.onConfirm}
      okText={props.okText || 'Yes'}
      okButtonProps={{ size: 'middle', color: 'red' }}
      cancelButtonProps={{ size: 'middle' }}
      cancelText={props.cancelText || 'No'}
    >
      {props.children}
    </Popconfirm>
  );
};

ConfirmPopup.propTypes = {
  title: PropTypes.string,
  placement: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func,
  children: PropTypes.any,
};

ConfirmPopup.defaultProps = {
  placement: 'rightBottom',
};
