import { Col as AntCol } from 'antd';
import { ColProps } from 'antd/lib/col/';
import React from 'react';

const Col: React.FC<ColProps> = (props) => {
  return (
      <AntCol {...props} />
  );
};

export default Col;
