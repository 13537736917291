import { Empty as AntEmpty } from 'antd';
import React, { CSSProperties, FC, ReactElement } from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';
import {
  NoEquipmentsFound,
  NoFieldsFound,
  NoProductsFound,
  NoPropertiesFound,
  NoSeasonsFound,
  NoTasksFound,
  NoUsersFound,
  UploadFileEmpty,
} from '../icon/Icon.assets';

interface IEmptyProps {
  description?: string;
  descriptionStyle?: CSSProperties;
  imageStyle?: CSSProperties;
  type?: keyof typeof imageMap;
}

const imageMap = {
  noFieldsFound: <NoFieldsFound/>,
  noEquipmentsFound: <NoEquipmentsFound/>,
  noSeasonsFound: <NoSeasonsFound/>,
  noPropertiesFound: <NoPropertiesFound/>,
  noUsersFound: <NoUsersFound/>,
  noTasksFound: <NoTasksFound/>,
  noProductsFound: <NoProductsFound/>,
  noFileUploaded: <UploadFileEmpty />,
};

const Empty: FC<IEmptyProps> = ({ type, imageStyle, description, descriptionStyle }): ReactElement => {
  return (
    <AntdConfigProvider>
      <AntEmpty
        image={imageMap[type || 'noFieldsFound']}
        imageStyle={imageStyle}
        description={(<span style={descriptionStyle}>{description}</span>)
        }
      />
    </AntdConfigProvider>
  );
};

export default Empty;
