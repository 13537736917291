import { Avatar as AntAvatar } from 'antd';
import React, { Component, CSSProperties } from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';

export type AvatarSize = 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large' | 'extra-extra-large' | 'default';

interface IAvatarProps {
  style?: CSSProperties;
  size?: AvatarSize | number;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  src?: string;
  className?: string;
  shape?: 'circle' | 'square';
  onError?: () => boolean;
}

const avatarSize = {
  'extra-small': 16,
  'small': 24,
  'medium': 32,
  'large': 48,
  'extra-large': 64,
  'extra-extra-large': 96,
  'default': 48,
};

export default class Avatar extends Component<IAvatarProps> {
  public render() {
    return (
      <AntdConfigProvider>
        <AntAvatar
          className={this.props.className}
          style={this.props.style}
          size={this.getAvatarSize()}
          icon={this.props.icon}
          shape={this.props.shape}
          src={this.props.src}
          onError={this.props.onError}
        >
          {this.props.children}
        </AntAvatar>
      </AntdConfigProvider>
    );
  }

  private getAvatarSize(): number {
    if (typeof this.props.size === 'string') {
      return avatarSize[this.props.size];
    } else if (typeof this.props.size === 'number') {
      return this.props.size;
    } else {
      return 48;
    }
  }
}
