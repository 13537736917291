import { Input as AntInput } from 'antd';
import { InputProps } from 'antd/lib/input';
import React, { Component } from 'react';

import { AntdConfigProvider } from '../../config/AntdConfigProvider';

export enum InputType {
  success = 'success',
  warn = 'warn',
  danger = 'danger',
  default = 'default',
}

export type SizeType = 'small' | 'middle' | 'large' | undefined;

export interface IInputProps extends InputProps {
  caption?: string;
  label?: string;
}

export default class Input extends Component<IInputProps> {
  // tslint:disable-next-line:member-access
  static TextArea: any;

  public constructor(props: IInputProps) {
    super(props);

    this.getAntdType = this.getAntdType.bind(this);
  }

  public render() {
    return (
      <AntdConfigProvider>
        <div className={`syt-antd-input-wrapper-${this.getAntdType()}`}>
          { this.props.label && (
            <div className="syt-antd-input-label">
              <label title={this.props.label}>{this.props.label}</label>
            </div>
          )}
          <AntInput
            {...this.props}
            type={this.getAntdType()}
          />
          { this.props.caption && (
            <div className="syt-antd-input-caption">
              <label title={this.props.caption}>{this.props.caption}</label>
            </div>
          )}
        </div>
      </AntdConfigProvider>
    );
  }

  private getAntdType(): InputType {
    switch (this.props.type) {
      case InputType.success:
        return 'success' as InputType;
      case InputType.warn:
        return 'warn' as InputType;
      case InputType.danger:
        return 'danger' as InputType;
      default:
        return 'default' as InputType;
    }
  }
}
