import React, { Component, CSSProperties } from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';
import icons from '../styles/assets/cropwise-icons/cropwise-icons.json';

export const IconTypes = icons;

export interface IIconProps {
  name: string;
  size?: number;
  color?: string;
  style?: CSSProperties;
}

export default class Icon extends Component<IIconProps> {
  public render() {
    return <AntdConfigProvider><i className={this.getIcon()} style={this.getStyle()}/></AntdConfigProvider>;
  }

  private getStyle() {
    const overriddenStyle = this.props.style ? this.props.style : {};
    const size = this.props.size ? this.props.size : 24;
    return {
      fontSize: `${size}px`,
      color: this.props.color ? this.props.color : undefined,
      ...overriddenStyle,
    };
  }

  private getIcon() {
    if (!this.props.name || !this.props.name.length) {
      return undefined;
    }
    return 'icon-' + this.props.name;
  }
}
