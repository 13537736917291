import { message } from 'antd';
import React, { CSSProperties } from 'react';
import { CheckCircleGreen, ErrorOutline, InfoBlue, ReportProblem } from '../icon/Icon.assets';

const SYT_PREFIX = 'syt-antd-message';
export interface IConfig {
  className?: string;
  content?: React.ReactNode;
  duration?: number;
  icon?: React.ReactNode;
  key?: string | number;
  style?: CSSProperties;
  onClose?: any;
  onClick?: any;
  dark?: boolean;
}

const darkModeHelper = (config: IConfig) => {
  return config.dark
  ? { ...config, className: `${config.className || ''} dark` }
  : config;
};
class MessageClass {
  public info(config: IConfig) {
    config = darkModeHelper(config);
    message.info({ prefixCls: SYT_PREFIX, icon: <InfoBlue/>, ...config });
  }

  public success(config: IConfig) {
    config = darkModeHelper(config);
    message.success({ prefixCls: SYT_PREFIX, icon: <CheckCircleGreen/>, ...config });
  }

  public warning(config: IConfig) {
    config = darkModeHelper(config);
    message.warning({ prefixCls: SYT_PREFIX, icon: <ReportProblem/>, ...config });
  }

  public error(config: IConfig) {
    config = darkModeHelper(config);
    message.error({ prefixCls: SYT_PREFIX, icon: <ErrorOutline/>, ...config });
  }

  public loading(config: IConfig) {
    config = darkModeHelper(config);
    message.loading({ prefixCls: SYT_PREFIX, ...config });
  }
}

const Message = new MessageClass();

export default Message;
