import { Tooltip as AntTooltip } from 'antd';
import { TooltipProps } from 'antd/lib/tooltip/';
import React from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';

const Tooltip: React.FC<TooltipProps> = (props) => {
  return (
      <AntdConfigProvider>
          <AntTooltip {...props} />
      </AntdConfigProvider>
  );
};

export default Tooltip;
