import React from "react";
import { useTranslation } from "react-i18next";
import { PageLayout } from "shared/layout/exports";
import { Card, Result } from "syngenta-digital-cropwise-react-ui-kit";
import error from "../../assets/images/error.svg";

export const ErrorDisplay = (props: any) => {
  const { t } = useTranslation();
  return (
    <PageLayout>
      <Card className="height-full">
        <Result
          title={t(props.title)}
          subTitle={t(props.subTitle)}
          icon={<img src={error} alt="" />}
        ></Result>
      </Card>
    </PageLayout>
  );
};
