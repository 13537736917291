// export { Switch as default } from 'antd';
// right now we are facing an issue while using this in another components.
// so i have disabled above line
// once demo is done will look into the issue
import { Switch as AntSwitch } from 'antd';
import React, { Component, CSSProperties } from 'react';

import { AntdConfigProvider } from '../../config/AntdConfigProvider';

export type SizeType = 'small' | 'medium' | undefined;

export interface ISwitchProps {
  autoFocus?: boolean;
  checked?: boolean;
  checkedChildren?: React.ReactNode;
  className?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  loading?: boolean;
  size?: SizeType;
  unCheckedChildren?: React.ReactNode;
  onChange?: (checked: boolean, event: Event) => void;
  onClick?: (checked: boolean, event: Event) => void;
  style?: CSSProperties;
}

export interface ISwitchState {
  checkedFlag: boolean;
}

export default class Switch extends Component<ISwitchProps, ISwitchState> {
  public constructor(props: ISwitchProps) {
    super(props);
    this.state = { checkedFlag: !!this.props.checked };
    this.getSize = this.getSize.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  public render() {
    return (
      <AntdConfigProvider>
        <AntSwitch
          {...this.props}
          onChange={this.handleChange}
          style={this.props.style}
          size={this.getSize()}
        />
      </AntdConfigProvider>
    );
  }

  private getSize() {
    return this.props.size === 'medium' ? 'default' : this.props.size;
  }
  private handleChange(checked: boolean, event: Event) {
    this.setState({ checkedFlag: checked });
    if (this.props.onChange) { this.props.onChange(checked, event); }
  }
}
