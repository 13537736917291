import { DatePicker as AntDatePicker } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker';
import React, { Component } from 'react';

import { AntdConfigProvider } from '../../config/AntdConfigProvider';

export default class DatePicker extends Component<DatePickerProps> {
  // tslint:disable-next-line:member-access
  static RangePicker: any;

  constructor(props: DatePickerProps) {
    super(props);
  }

  public render() {
    return (
      <AntdConfigProvider>
        <AntDatePicker {...this.props} />
      </AntdConfigProvider>
    );
  }
}
