/* tslint:disable */
const analytics = require('react-segment');

/*
Usage:
initialize: Analytics.init('e8jHwSMD5aKOa2c7x1LPTxGBFkuJqu4Y');
log/track event: Analytics.track().page('Homepage from app track 007');
https://github.com/segmentio/analytics-react
https://github.com/anishmprasad/react-segment
*/
const Analytics = {
  init: (secretKey: any) => {
    if (!secretKey) {
      console.error('Invalid Key');
      return;
    }
    analytics.default.load(secretKey);
  },
  record: () => {
    return (window as any).analytics;
  },
};

export { Analytics };
