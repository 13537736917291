import { PageHeader as AntPageHeader } from 'antd';
import { PageHeaderProps } from 'antd/lib/page-header';
import React from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';

const PageHeader: React.FC<PageHeaderProps> = (props) => {
  return (
    <AntdConfigProvider>
      <AntPageHeader {...props} />
    </AntdConfigProvider>
  );
};

export default PageHeader;
