import { Breadcrumb as AntBreadcrumb } from 'antd';
import { BreadcrumbItemProps } from 'antd/lib/breadcrumb';
import React, { FC } from 'react';

import { AntdConfigProvider } from '../../config/AntdConfigProvider';

const BreadcrumbItem: FC<BreadcrumbItemProps> = (props) => {
  return (
    <AntdConfigProvider>
      <AntBreadcrumb.Item {...props} />
    </AntdConfigProvider>
  );
};

export default BreadcrumbItem;
