import { notification } from 'antd';
import { ConfigProps } from 'antd/lib/notification';
import React, { CSSProperties } from 'react';
import { CheckCircleGreen, ErrorOutline, InfoBlue, ReportProblem } from '../icon/Icon.assets';
import { Button, ButtonType } from './../index';

const SYT_PREFIX = 'syt-antd-notification';
export interface IConfigNotification extends ConfigProps {
  action?: boolean;
  actionText?: string;
  actionDismissText?: string;
  btn?: React.ReactNode;
  className?: string;
  description: string;
  message: string;
  icon?: React.ReactNode;
  key?: string;
  style?: CSSProperties;
  onActionClick?: any;
  onClose?: any;
  onClick?: any;
}

const actionHelper = (config: IConfigNotification) => {
  if (config.action) {
    config.duration = config.duration || 0;
    if (!config.btn) {
      const key = config.key ? config.key : `open${Date.now()}`;
      const onActionClickHandler = () => {
        config?.onActionClick();
        notification.close(key);
      };
      config.key = key;
      const closeHandler = () => {
        notification.close(key);
      };
      config.btn = (
        <>
          <Button type={ButtonType.outline} size="middle" onClick={closeHandler}>{config.actionDismissText || 'Dismiss'}</Button>
          <Button type={ButtonType.primary} size="middle" onClick={onActionClickHandler}>{config.actionText || 'Action'}</Button>
        </>
      );
    }
  } else {
    config.btn = null;
  }
  return config;
};

class NotificationClass {
  public info(config: IConfigNotification) {
    const updatedClassName = `syt-antd-info-notification ${config.className || ''}`;
    config = actionHelper(config);
    notification.info({ prefixCls: SYT_PREFIX, icon: <InfoBlue/>, ...config, className: updatedClassName });
  }

  public success(config: IConfigNotification) {
    config = actionHelper(config);
    const updatedClassName = `syt-antd-success-notification ${config.className || ''}`;
    notification.success({ prefixCls: SYT_PREFIX, icon: <CheckCircleGreen/>, ...config, className: updatedClassName });
  }

  public warning(config: IConfigNotification) {
    config = actionHelper(config);
    const updatedClassName = `syt-antd-warning-notification ${config.className || ''}`;
    notification.warning({ prefixCls: SYT_PREFIX, icon: <ReportProblem/>, ...config, className: updatedClassName });
  }

  public error(config: IConfigNotification) {
    config = actionHelper(config);
    const updatedClassName = `syt-antd-error-notification ${config.className || ''}`;
    notification.error({ prefixCls: SYT_PREFIX, icon: <ErrorOutline/>, ...config, className: updatedClassName });
  }
}

const Notification = new NotificationClass();

export default Notification;
