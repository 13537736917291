import { Alert as AntAlert } from 'antd';
import { AlertProps } from 'antd/lib/alert/index';
import React, { CSSProperties, FC, ReactElement } from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';
import { CheckCircleFill, ErrorFill, InfoBlue, Warning } from '../icon/Icon.assets';
export interface IAlertProps extends AlertProps {
  afterClose?: () => void;
  onClose?: () => void;
  banner?: boolean;
  closeText?: React.ReactNode;
  description?: React.ReactNode;
  message: React.ReactNode;
  showIcon?: boolean;
  children?: React.ReactNode;
  style?: CSSProperties;
  icon?: React.ReactNode;
}

const classNameHelper = (descriptionArg: React.ReactNode) => {
  return descriptionArg ? '' : 'custom-alert-icon';
};

const iconHandler = (type?: string, description?: React.ReactNode) => {
  let icon;
  switch (type) {
    case 'success':
      icon = <CheckCircleFill className={classNameHelper(description)}/>;
      break;
    case 'info':
      icon = <InfoBlue className={classNameHelper(description)}/>;
      break;
    case 'warning':
      icon = <Warning className={classNameHelper(description)}/>;
      break;
    case 'error':
      icon = <ErrorFill className={classNameHelper(description)}/>;
      break;
    default:
  }
  return icon;
};

const Alert: FC<IAlertProps> = ({ ...props }): ReactElement => {
  let customIcon;
  if (props.showIcon) {
    customIcon = iconHandler(props.type, props.description);
  }
  return (
    <AntdConfigProvider>
      <AntAlert className={props.description ? '' : 'empty-description'} icon={customIcon} {...props}/>
    </AntdConfigProvider>
  );
};

export default Alert;
