import { Layout } from "antd";
import React from "react";
import styled from "styled-components";
import { AppBreadcrumb, ErrorBoundary } from "./exports";

const { Content } = Layout;

const StyledLayout = styled(Layout)`
  height: 100%;
  min-height: 100%;
`;

const StyledContent = styled(Content)`
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
`;

export const PageLayout: React.FC = (props: any) => {
  return (
    <StyledLayout>
      <StyledContent>
        {props.hasBreadcrumb === true && <AppBreadcrumb />}
        <ErrorBoundary>{props.children}</ErrorBoundary>
      </StyledContent>
    </StyledLayout>
  );
};
