import { Radio as AntRadio } from 'antd';
import React, { FC } from 'react';

export interface IRadioButtonProps {
  autoFocus?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  value?: any;
}

const RadioButton: FC<IRadioButtonProps> = (props) => {
  return (
    <AntRadio.Button {...props}>
      {props.children}
    </AntRadio.Button>
  );
};

RadioButton.defaultProps = {
  autoFocus: false,
  checked: false,
  defaultChecked: false,
  disabled: false,
};

export default RadioButton;
