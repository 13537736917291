import { Checkbox as AntCheckbox } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import React from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';

const Checkbox = (props: CheckboxProps) => {
  return (
    <AntdConfigProvider>
      <AntCheckbox {...props} />
    </AntdConfigProvider>
  );
};

export default Checkbox;
