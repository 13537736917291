import { Layout, Menu as AntMenu } from 'antd';
import { MenuProps as RcMenuProps } from 'rc-menu';
import React, { Component, CSSProperties } from 'react';
import { DashboardOutline, MenuCollapse, MenuExpand } from '..';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';
import { Analytics } from '../../core/utils/Analytics';
import Tooltip from '../tooltip';

export type MenuTheme = 'light' | 'dark';

interface IMenuItemProps {
  key: string;
  name?: string;
  icon?: string | (() => void) | JSX.Element;
  disabled?: boolean;
  submenus?: IMenuItemProps[];
  onClick?: () => void;
}

export interface IMenuProps extends RcMenuProps {
  collapsedIcon?: string | (() => void) | JSX.Element;
  expandedIcon?: string | (() => void) | JSX.Element;
  menuItems?: IMenuItemProps[];
  selectedKeys?: string[];
  theme?: MenuTheme;
  versionNumber?: string;
  style?: CSSProperties;
  collapsedMenu?: boolean;
  triggerCloseButton?: JSX.Element;
  triggerExpandButton?: JSX.Element;
  shouldApplyPrefixClass?: boolean;
}

export interface IMenuStateProps {
  collapsed: boolean;
  currentSubmenu: string;
  collapsedMenu: boolean;
}

const { Sider } = Layout;
const { SubMenu } = AntMenu;

interface ISidebarSubMenuProps {
  menuItem?: IMenuItemProps;
  getSubmenuIcon: (submenuItem: IMenuItemProps) => any;
  collapsed: boolean;
}

const SidebarSubMenu: React.FC<ISidebarSubMenuProps> = ({ menuItem, getSubmenuIcon, collapsed, ...other }) => (
  <SubMenu
    {...other}
    key={menuItem?.key}
    title={collapsed ? undefined : menuItem?.name}
    disabled={menuItem?.disabled}
    icon={
      typeof menuItem?.icon === 'string' ?
      getSubmenuIcon(menuItem) :
      <div className="submenuElementIcon">{menuItem?.icon}</div>
    }
    onTitleClick={menuItem?.onClick}
  >
  {menuItem?.submenus?.map((submenuItem: IMenuItemProps) => (
        <AntMenu.Item key={submenuItem.key} title="" onClick={submenuItem.onClick} disabled={submenuItem.disabled}>
          { typeof submenuItem.icon === 'string' ?
            getSubmenuIcon(submenuItem) :
            <div className="submenuElementIcon">{submenuItem.icon}</div>
          }
         <p className="submenuName">{submenuItem.name}</p>
        </AntMenu.Item>
  ))}
  </SubMenu>
);
export default class Sidebar extends Component<IMenuProps> {

  public static getDerivedStateFromProps(props: IMenuProps, state: IMenuStateProps) {
    if ('collapsedMenu' in props && props.collapsedMenu !== state.collapsed && props.collapsedMenu !== state.collapsedMenu) {
      return {
        collapsed: props.collapsedMenu,
        collapsedMenu: props.collapsedMenu,
      };
    } else {
      return null;
    }
  }
  public state = {
    collapsed: false,
    currentSubmenu: '1',
    collapsedMenu: false,
  };

  public toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  public onCollapse = (collapsed: boolean) => {
    this.setState({ collapsed });
  }

  public handleSubmenuClick = (e: any) => {
    this.setState({
      currentSubmenu: e.key,
    });
  }

  public getTopLogo = () => {
    return this.state.collapsed ? this.props.collapsedIcon : this.props.expandedIcon;
  }

  // tslint:disable-next-line: cognitive-complexity
  public render() {
    const handleSidebarClicked = () => {
      if (this.state.collapsed) {
        Analytics.record().track('Nav bar - Tapped on Expand');
      }
    };
    const triggerExpandButton = ('triggerExpandButton' in this.props) ? this.props.triggerExpandButton : <MenuExpand className="triggerExpandButton" />;
    const triggerCloseButton = ('triggerCloseButton' in this.props) ? this.props.triggerCloseButton : <MenuCollapse className="triggerCloseButton" />;
    return (
      <AntdConfigProvider prefixCls={this.props.shouldApplyPrefixClass ? 'syt-antd' : ''} >
        <Sider
          theme={this.props.theme}
          trigger={this.state.collapsed ? triggerExpandButton : triggerCloseButton}
          width="280"
          collapsedWidth="64"
          collapsible={true}
          collapsed={this.state.collapsed}
          onCollapse={this.onCollapse}
          onClick={handleSidebarClicked}
        >
          <div className="CropwiseLogo">
            {this.getTopLogo()}
          </div>
          <AntMenu
            {...this.props}
            onClick={this.handleSubmenuClick}
            onSelect={this.props.onSelect}
            onDeselect={this.props.onDeselect}
            onOpenChange={this.props.onOpenChange}
            selectedKeys={this.props.selectedKeys ? this.props.selectedKeys : [this.state.currentSubmenu]}
            theme={this.props.theme}
            mode="inline"
            style={this.props.style}
            title={this.props.title}
          >
            {this.props.menuItems?.map((menuItem) =>
                menuItem.submenus ? (
                  <SidebarSubMenu menuItem={menuItem} getSubmenuIcon={this.getSubmenuIcon} collapsed={this.state.collapsed}/>
                ) :
                (
                  <AntMenu.Item disabled={menuItem.disabled} key={menuItem.key} title="" onClick={menuItem.onClick}>
                    <Tooltip title={this.state.collapsed && menuItem.name} placement="right" mouseLeaveDelay={0}>
                    { typeof menuItem.icon === 'string' ?
                      this.getSubmenuIcon(menuItem) :
                      <div className="submenuElementIcon">{menuItem.icon}</div>
                    }
                    {this.state.collapsed ? <></> : <p className="submenuName">{menuItem.name}</p>}
                    </Tooltip>
                  </AntMenu.Item>
                ),
              )
            }
          </AntMenu>
          <div className="sidebarFooter">{this.props.versionNumber}</div>
        </Sider>
      </AntdConfigProvider>
    );
  }

  private getSubmenuIcon(menuItem: IMenuItemProps): JSX.Element {
    if (menuItem.icon === 'CropwiseDefaultIcon') {
      return <DashboardOutline className="submenuIcon" />;
    } else {
      return <></>;
    }
  }
}
