import { Progress as AntProgress } from 'antd';
import { ProgressProps } from 'antd/lib/progress/';
import React from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';

const Progress: React.FC<ProgressProps> = (props) => {
  return (
    <AntdConfigProvider>
      <AntProgress {...props} />
    </AntdConfigProvider>
  );
};

export default Progress;
