import { DatePicker as AntDatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import React, { FC } from 'react';

import { AntdConfigProvider } from '../../config/AntdConfigProvider';

const RangePicker: FC<RangePickerProps> = (props) => {
  return (
    <AntdConfigProvider>
      <AntDatePicker.RangePicker {...props} />
    </AntdConfigProvider>
  );
};

export default RangePicker;
