import { Tag as AntTag } from 'antd';
import { CheckableTagProps } from 'antd/lib/tag';
import React from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';

const CheckableTag = (props: CheckableTagProps) => {
  return (
    <AntdConfigProvider>
      <AntTag.CheckableTag {...props} />
    </AntdConfigProvider>
  );
};

export default CheckableTag;
