import range from 'lodash/range';
import moment from 'moment';

export const months = moment.monthsShort();

export const monthsShorts = months.map((m) => m.charAt(0));

export const arrayOfDaysInEachMonth = (year: number) => {
  return range(1, 13).map((month: number) => {
    const m = month < 10 ? `0${month}` : month;
    return moment(`${year}-${m}`, 'YYYY-MM').daysInMonth();
  });
};
