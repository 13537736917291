import { Spin as AntSpin } from 'antd';
import { SpinProps } from 'antd/lib/spin/';
import React from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';
import { LoadingOutlined } from '../icons';

const Spin: React.FC<SpinProps> = (props) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin={true} />;
  return (
    <AntdConfigProvider>
      {props.className === 'syt-default' ?
        <AntSpin indicator={antIcon} {...props} /> :
        <AntSpin {...props} />
      }
    </AntdConfigProvider>
  );
};

export default Spin;
