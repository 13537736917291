import { Row } from "antd";
import { FONT } from "constants.js";
import React from "react";

export const PanelHeader = ({
  title,
  subTitle,
}: {
  title: string;
  subTitle: string;
}) => {
  return (
    <>
      <Row>
        <p style={FONT}>{title}</p>
      </Row>
      <Row>
        <p style={{ fontWeight: 400, fontSize: 14 }}>{subTitle}</p>
      </Row>
    </>
  );
};
