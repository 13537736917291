import { Radio as AntRadio } from 'antd';
import React, { FC } from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';

export type ButtonStyleType = 'outline'| 'solid';

export type OptionType = 'default' | 'button';

export type SizeType = 'large' | 'middle' | 'small';

export interface IRadioChangeEventTarget {
  checked: boolean;
}

export interface IRadioChangeEvent {
  target: IRadioChangeEventTarget;
  stopPropagation: () => void;
  preventDefault: () => void;
  nativeEvent: MouseEvent;
}

export interface IRadioGroupProps {
  buttonStyle?: ButtonStyleType;
  defaultValue?: any;
  disabled?: boolean;
  name?: string;
  optionType?: OptionType;
  size?: SizeType;
  value?: any;
  onChange?: (e: IRadioChangeEvent) => void;
}

const RadioGroup: FC<IRadioGroupProps> = (props) => {
  return (
    <AntdConfigProvider>
      <AntRadio.Group {...props}>
        {props.children}
      </AntRadio.Group>
    </AntdConfigProvider>
  );
};

RadioGroup.defaultProps = {
  buttonStyle: 'outline',
  disabled: false,
  optionType: 'default',
};

export default RadioGroup;
