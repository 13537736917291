import { Typography } from 'antd';
import React, { Component, CSSProperties } from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';
import ICopyable from './ICopyable';
import IEditable from './IEditable';

const { Paragraph } = Typography;

export interface IParagraphProps {
  className?: string;
  code?: boolean;
  copyable?: boolean | ICopyable;
  delete?: boolean;
  disabled?: boolean;
  editable?: boolean | IEditable;
  ellipsis?: boolean;
  mark?: boolean;
  underline?: boolean;
  onChange?: (arg0: string) => void;
  strong?: boolean;
  style?: CSSProperties;
}

export class UIParagraph extends Component<IParagraphProps> {
  public render() {
    return (
      <AntdConfigProvider>
        <Paragraph {...this.props} className={`cwsTypography ${this.props.className ?? ''}`}>{this.props.children}</Paragraph>
      </AntdConfigProvider>
    );
  }
}
export default Paragraph;
