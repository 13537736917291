import { Table as AntTable } from 'antd';
import { TableProps } from 'antd/lib/table';
import React from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';

const Table: React.FC<TableProps<any>> = (props) => (
  <AntdConfigProvider>
    <AntTable {...props} />
  </AntdConfigProvider>
);

export default Table;
