import { Badge as AntBadge } from 'antd';
import { BadgeProps } from 'antd/lib/badge/';
import React from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';

const Badge: React.FC<BadgeProps> = (props) => {
  return (
    <AntdConfigProvider>
      <AntBadge {...props} />
    </AntdConfigProvider>
  );
};

export default Badge;
