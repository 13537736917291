import { Button, Modal } from "antd";
import React, { MouseEvent } from "react";
import { useTranslation } from "react-i18next";

type ModalWithTitleDescriptionWithFormComponentProps = {
  title?: string;
  description?: string;
  onCancel?: (event: MouseEvent<HTMLElement>) => void;
  onSave?: () => void;
  okText?: string;
  cancelText?: string;
  loading?: boolean;
  children?: JSX.Element;
  cancelButtonProps?: {};
  okButtonProps?: {};
  descriptionTextClassName?: string;
  closable?: boolean;
  className?: string;
  style?: {};
  footer?: any;
  width?: any;
  destroyOnClose?: boolean;
};

export const ModalWithTitleDescriptionWithFormComponent = (
  props: ModalWithTitleDescriptionWithFormComponentProps
) => {
  const { t } = useTranslation();

  return (
    <Modal
      destroyOnClose={props?.destroyOnClose}
      width={props?.width}
      title={t(props?.title ?? "")}
      style={props?.style}
      centered
      cancelButtonProps={props?.cancelButtonProps}
      okButtonProps={props?.okButtonProps}
      visible={true}
      closable={props?.closable}
      maskClosable={false}
      keyboard={false}
      footer={
        props?.hasOwnProperty("footer")
          ? props?.footer
          : [
              <Button key="back" onClick={props.onCancel} data-cy="back">
                {props?.cancelText ?? "Cancel"}
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={props?.loading}
                onClick={props.onSave}
                data-cy="submit"
              >
                {props?.okText}
              </Button>,
            ]
      }
      confirmLoading={props?.loading}
      okText={props?.okText ?? t("Save")}
      onCancel={props.onCancel}
      cancelText={props?.cancelText ?? t("Cancel")}
      className={props?.className ?? "modal-framework__notification"}
    >
      {props?.description && (
        <p className={props?.descriptionTextClassName}>
          {t(props?.description ?? "")}
        </p>
      )}
      {props.children}
    </Modal>
  );
};
