import { Upload as AntUpload } from 'antd';
import { DraggerProps } from 'antd/lib/upload/Dragger';
import React, { Component } from 'react';

import { AntdConfigProvider } from '../../config/AntdConfigProvider';
import { CloseGlyph, PaperclipGlyph } from '../icon/Icon.assets';

class Dragger extends Component<DraggerProps> {
  // tslint:disable-next-line:no-unused-variable
  public static defaultProps: any;

  public constructor(props: DraggerProps) {
    super(props);
  }

  public render() {
    return (
      <AntdConfigProvider>
        <AntUpload.Dragger
          {...this.props}
        />
      </AntdConfigProvider>
    );
  }
}

Dragger.defaultProps = {
  directory: false,
  disabled: false,
  listType: 'text',
  method: 'post',
  multiple: false,
  name: 'file',
  openFileDialogOnClick: true,
  progress: { strokeWidth: 2, showInfo: false },
  showUploadList: {
    previewIcon: <PaperclipGlyph/>,
    removeIcon: <CloseGlyph/>,
  },
  withCredentials: false,
};

export default Dragger;
