import { useAuth } from "lib/auth/exports";
import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { getActiveModules } from "shared/utils/common-methods";
import { FrameworkByPartner } from "./frameworks-by-partner";
import NotFound from "./not-found";

export const AppRoutes = () => {
  const { routePaths } = useAuth();
  const routes = getActiveModules(routePaths);
  return (
    <Routes>
      {Object.keys(routes).map((route: any) => {
        const Component = lazy(() => import(`./${route.toLowerCase()}`));
        return (
          <Route
            key={route}
            path={`/app${(routes as any)[route].path}/*`}
            element={<Component />}
          />
        );
      })}
      <Route
        path="/app/frameworks-by-partner"
        element={<FrameworkByPartner />}
      />
      <Route
        path="/"
        element={<Navigate replace to="/app/frameworks-by-country" />}
      />
      <Route key="not-found" path="*" element={<NotFound />} />
    </Routes>
  );
};
