/*
 * https://kentcdodds.com/blog/authentication-in-react-applications
 * https://www.digitalocean.com/community/tutorials/react-manage-user-login-react-context
 */
import queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Loader } from "shared/layout/exports";
import { initRollout } from "../../rollout.actions";
import { authService } from "./exports";

const AUTH_META: AuthInterface = {
  status: "idle",
  error: null,
  user: null,
  userRole: "",
};

const AuthContext = React.createContext<AuthInterface>(AUTH_META);

function AuthProvider(props: any) {
  const [hasToken, setHasToken] = useState(false);
  const [logoutStatus, setLogoutStatus] = useState<boolean>(false);
  const [code, setCode] = useState<any>(null);
  const [appLoading, setAppLoading] = useState<any>(null);
  const [featureflag, setFeatureflag] = useState<any>(false);
  const [routePaths, setRoutePaths] = useState<Object>({});

  const updateRoutePaths = (featureflagObj: { [k: string]: {} }) => {
    let routes: any = {
      "FRAMEWORKS-BY-COUNTRY": {
        name: "Frameworks by country",
        path: "/frameworks-by-country",
      },
      REPORTS: { name: "Reports", path: "/reports" },
      PARTNERS: { name: "Partners", path: "/partners" },
      COUNTRIES: {
        name: "Countries",
        path: "/countries",
        featureflag: featureflagObj.showCountriesModule,
      },
      LANGUAGES: {
        name: "Languages",
        path: "/languages",
        featureflag: featureflagObj.enableLanguagesModule,
      },
      CROPS: {
        name: "Crops",
        path: "/crops",
        featureflag: featureflagObj.enableCropsModule,
      },
    };
    setRoutePaths(routes);
  };
  const initrolloutFlag = async (config: any = {}) => {
    const featureflagObj = await (await initRollout(config)).payload;
    setFeatureflag(featureflagObj);
    updateRoutePaths(featureflagObj);
  };
  useEffect(() => {
    const query = queryString.parse(window.location.search);
    if (query?.code) {
      if (!sessionStorage.getItem(`cod${query.code}`)) {
        setCode(query.code);
      }
    } else if (authService.isAccessTokenAvailable()) {
      setHasToken(true);
    } else {
      window.location.href = authService.getOAuthUrl();
    }
  }, []);

  const { status: authStatus, error: authError } = useQuery(
    ["oAuthCode", code],
    () => authService.performLogin(code),
    { enabled: !!code }
  );

  const {
    status,
    data: user,
    error,
  } = useQuery(["currentUser"], authService.currentUser, {
    enabled: (!!code && authStatus === "success") || hasToken,
  });

  const { data: userRole } = useQuery(["userRole"], authService.getUserRole, {
    enabled: (!!code && authStatus === "success") || hasToken,
  });
  let loaderComponent = props.children;
  if ((status === "error" && error) || (authStatus === "error" && authError)) {
    window.location.href = authService.getOAuthUrl();
  } else if (["loading", "idle"].includes(status)) {
    loaderComponent = <Loader mask={true} message={`${"Authenticating..."}`} />;
  } else if (status === "error") {
    loaderComponent = <Loader mask={true} message="Error..." />;
  }

  const contextProvider = useMemo(
    () => ({
      status,
      logoutStatus,
      setLogoutStatus,
      user,
      error,
      appLoading,
      setAppLoading,
      userRole: userRole as string,
      featureflag,
      routePaths,
      initrolloutFlag,
    }),
    [
      status,
      logoutStatus,
      setLogoutStatus,
      user,
      error,
      appLoading,
      setAppLoading,
      userRole,
      featureflag,
      routePaths,
      initrolloutFlag,
    ]
  );

  return (
    <AuthContext.Provider value={contextProvider}>
      {loaderComponent}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  const isPending = ["loading", "idle"].includes(context.status ?? "");
  const isError = context.status === "error";
  const isSuccess = context.status === "success";
  const isAuthenticated = context.user && isSuccess;
  return {
    ...context,
    logout: authService.logout,
    logoutStatus: context.logoutStatus,
    setLogoutStatus: context.setLogoutStatus,
    isPending,
    appLoading: context.appLoading,
    setAppLoading: context.setAppLoading,
    isSuccess,
    isError,
    isAuthenticated,
  };
}

interface AuthInterface {
  user?: any;
  logoutStatus?: boolean;
  error?: any;
  setLogoutStatus?: any;
  appLoading?: any;
  setAppLoading?: any;
  status?: string;
  userRole?: string;
  featureflag?: any;
  routePaths?: {};
  initrolloutFlag?: any;
}

export { AuthProvider, useAuth };
