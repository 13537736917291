import { Divider as AntDivider } from 'antd';
import { DividerProps } from 'antd/lib/divider/';
import React from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';

const Divider: React.FC<DividerProps> = ({ style, ...props }) => {
  return (
    <AntdConfigProvider>
      <AntDivider style={style} {...props} />
    </AntdConfigProvider>
  );
};

export default Divider;
