import { authService, useAuth } from "lib/auth/exports";
import { AppRoutes } from "modules/exports";
import React, { Suspense, useEffect, useState } from "react";
import {
  ErrorBoundary,
  GlobalHeader,
  Loader,
  RequestAccessAddon,
  SidebarMenu,
  ThemeProvider,
} from "shared/layout/exports";
import { Layout } from "syngenta-digital-cropwise-react-ui-kit";
import "syngenta-digital-cropwise-react-ui-kit/dist/styles/cw-ui-kit.less";
import "./main.less";
import * as Sentry from "@sentry/react";

const { Content } = Layout;

function App() {
  const [userHasAdminAccess, setUserHasAdminAccess] = useState<boolean>(true);
  const { user, logoutStatus, appLoading, featureflag, initrolloutFlag } =
    useAuth();

  useEffect(() => {
    const getPayloadForRBACAdminValidation = () => {
      return {
        operations: [
          {
            action: "admin_access:read",
            resource: "crn:app:339eddea-03cd-4dd1-839d-faa7e33b8f60",
          },
        ],
      };
    };

    const checkAdminConsoleAccess = async () => {
      const payloadValidation = getPayloadForRBACAdminValidation();
      const result = await authService.performAdminValidation(
        payloadValidation
      );
      return result;
    };

    const checkRBACAccess = async () => {
      const isUserAdmin = await checkAdminConsoleAccess();
      if (isUserAdmin) {
        setUserHasAdminAccess(true);
      } else {
        setUserHasAdminAccess(false);
      }
    };
    checkRBACAccess();
    if (user.email) {
      initrolloutFlag({ email: user.email });
    }
  }, [user]);

  if (!user) {
    return null;
  }

  if (logoutStatus) {
    return <Loader mask={true} message="Logging out of the application..." />;
  }

  if (featureflag === false) {
    return <Loader mask={true} message="Loading..." />;
  }
  // if (featureflag.isSystemOnMaintainence == undefined) {
  //   return <Loader mask={true} />;
  // }
  // !featureflag.isSystemOnMaintainence ? <SystemDown /> :
  return (
    <ErrorBoundary>
      <ThemeProvider>
        <div className="app-root">
          <GlobalHeader />
          <Layout className="cw-page-container">
            {appLoading && <Loader mask={true} message={appLoading} />}
            <Suspense fallback={<Loader mask={true} message="Loading..." />}>
              <SidebarMenu />
              {user && !userHasAdminAccess ? (
                <RequestAccessAddon />
              ) : (
                <Layout className="main-layout">
                  <Content className="main-container">
                    <AppRoutes />
                  </Content>
                </Layout>
              )}
            </Suspense>
          </Layout>
        </div>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default Sentry.withProfiler(App);
