import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Menu, Typography } from 'antd';
import React, { Component } from 'react';

import { AntdConfigProvider } from '../../config/AntdConfigProvider';
import { Avatar, Divider, Layout, Popover } from './../index';

import CheckMark from '../icon/assets/Minor/check.svg';
import { ExpandMore } from '../icon/Icon.assets';

const { Paragraph } = Typography;
interface ISubMenuProps {
  key: string;
  name?: string;
  icon?: (() => React.ReactNode) | string;
  disabled?: boolean;
  onClick?: () => void;
}

interface IContextBarProps {
  key: string;
  header?: string;
  name?: string;
  iconSrc?: () => React.ReactNode;
  iconInitials?: string;
  showRightNotch?: boolean;
  subMenu?: ISubMenuProps[];
  groupName?: string;
  disabled?: boolean;
  onClick?: () => void;
  onClickMenu?: (value: any) => void;
  goToLinkText?: string;
  goToLinkFunction?: (value: any) => void;
  menuName?: string;
}
export interface ITopNavBarProps {
  onClick?: () => void;
  onDeselect?: () => void;
  onOpenChange?: () => void;
  onSelect?: () => void;
  navbarIcon?: () => React.ReactNode;
  contextBars?: IContextBarProps[];
  defaultSelectedKeys?: string[];
  navbarUserInitials?: string;
  deckOfApps?: React.ReactNode;
  newDesign?: boolean;
  assistant?: React.ReactNode;
  title?: string;
  profileDropDown?: React.ReactNode;
}

const { Header } = Layout;
const { SubMenu } = Menu;

interface IContextBarSubMenuProps {
  contextBar?: IContextBarProps;
  getContextBarIcon: (
    contextBar: IContextBarProps,
  ) => React.ReactNode | undefined;
  getSubmenuIcon: (submenuItem: ISubMenuProps) => React.ReactNode | undefined;
}

const ContextBarSubMenu: React.FC<IContextBarSubMenuProps> = ({
  contextBar,
  getContextBarIcon,
  getSubmenuIcon,
  ...other
}) => (
  <SubMenu
    {...other}
    key={contextBar?.key}
    className="submenuContextBar"
    title={(
      <>
        {contextBar?.showRightNotch ? (
          <div className="submenuArrow">
            <DownOutlined style={{ fontSize: '12px' }} />
          </div>
        ) : (
          <></>
        )}
        <p className="contextBarTitle">{contextBar?.header}</p>
        <p className="contextBarSubTitle">{contextBar?.name}</p>
      </>
      )
    }
    icon={
      !contextBar?.iconSrc && !contextBar?.iconInitials ? (
        <></>
      ) : (
        <div className="navbarContextIcon">
          <Avatar shape="circle" size={40}>
            {getContextBarIcon(contextBar)}
          </Avatar>
        </div>
      )
    }
  >
    {contextBar?.groupName ? (
      <Menu.ItemGroup title={contextBar?.groupName}>
        {contextBar.subMenu?.map((submenuItem: ISubMenuProps) => (
          <Menu.Item
            key={submenuItem.key}
            title={submenuItem.name}
            onClick={submenuItem.onClick}
            disabled={submenuItem.disabled}
          >
            <div className="submenuContextIcon">
              <Avatar shape="square" size="small">
                {getSubmenuIcon(submenuItem)}
              </Avatar>
            </div>
            <p className="submenuTitle">{submenuItem.name}</p>
          </Menu.Item>
        ))}
      </Menu.ItemGroup>
    ) : (
      contextBar?.subMenu?.map((submenuItem: ISubMenuProps) => (
        <Menu.Item
          key={submenuItem.key}
          title={submenuItem.name}
          onClick={submenuItem.onClick}
          disabled={submenuItem.disabled}
        >
          <div className="submenuContextIcon">
            <Avatar shape="square" size="small">
              {getSubmenuIcon(submenuItem)}
            </Avatar>
          </div>
          <p className="submenuTitle">{submenuItem.name}</p>
        </Menu.Item>
      ))
    )}
  </SubMenu>
);

export default class TopNavBar extends Component<ITopNavBarProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isPropertyVisible: false,
      isOrgVisible: false,
    };
    this.handleVisibleProperty = this.handleVisibleProperty.bind(this);
    this.handleVisibleOrg = this.handleVisibleOrg.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
  }
  public handleVisibleProperty(visible: any) {
    this.setState({ isPropertyVisible: visible });
  }
  public handleVisibleOrg(visible: any) {
    this.setState({ isOrgVisible: visible });
  }
  public onClickHandler(org: any, contextBar: any) {
    contextBar.onClickMenu(org.key);
    if (contextBar.header === 'Organization') {
      this.handleVisibleOrg(false);
    } else {
      this.handleVisibleProperty(false);
    }
  }

  public render() {
    return (
      <AntdConfigProvider>
        <Header className="navbar-header">
          {this.props.deckOfApps ? <div style={{ padding: '9px 0px' }}>{this.props.deckOfApps}</div> : null}
          {this.props.navbarIcon &&
            <div className="navbarLogo">{this.getNavbarIcon()}</div>
          }
          {
            this.props.title && <div className="navbar-header-title" >{this.props.title}</div>
          }
          <div className="navbar-header-menus">
            {this.props.newDesign ? (
              <>
                {this.props.contextBars?.map((contextBar) =>
                  contextBar.subMenu ? (this.getNewDesignMenus(contextBar)
                  ) : (
                    <span>
                      <span className="title">{contextBar.header}</span>
                      <span className="subTitle">
                        <Paragraph ellipsis={true} style={{ display: 'inline' }}>
                          {contextBar.name}{' '}
                        </Paragraph>
                      </span>{' '}
                    </span>
                  ),
                )}
              </>
            ) : (
              <Menu
                onClick={this.props.onClick}
                onSelect={this.props.onSelect}
                onDeselect={this.props.onDeselect}
                onOpenChange={this.props.onOpenChange}
                theme="light"
                mode="horizontal"
                defaultSelectedKeys={this.props.defaultSelectedKeys}
              >
                {this.props.contextBars?.map((contextBar) =>
                  contextBar.subMenu ? (
                    <ContextBarSubMenu
                      contextBar={contextBar}
                      getContextBarIcon={this.getContextBarIcon}
                      getSubmenuIcon={this.getSubmenuIcon}
                    />
                  ) : this.getMenuItem(contextBar),
                )}
              </Menu>
            )}
          </div>
          <div className="navbar-header-end-div">
            <div className="navbarUserArea">
              {this.props.assistant ? this.props.assistant : null}
              <Divider
                className="navbarDivider"
                type="vertical"
                orientation="center"
                style={{ marginLeft: '6px' }}
              />
             {this.getUserProfile()}
            </div>
          </div>
        </Header>
      </AntdConfigProvider>
    );
  }
  private popoverContent(contextBar: any): React.ReactNode | undefined {
    return (
      <div style={{ width: '320px' }} className="navbar-menu-popover-div">
        <div className="navbar-menu-popover-inner-content">
          {!!contextBar.subMenu &&
            contextBar.subMenu.map((org: any) => {
              return (
                <div
                  className={`org-name-container ${
                    org.key === contextBar.key ? 'active-org' : ''
                  }`}
                  key={org.key}
                  id={org.id}
                  onClick={this.onClickHandler.bind(this, org, contextBar)}
                >
                  {contextBar.menuName === 'property' && (
                    <div
                      style={{ marginRight: '12px' }}
                      className="property-check-icon"
                    >
                      {org.key === contextBar.key && <CheckMark />}
                    </div>
                  )}
                  <div
                    className={`org-name header-popover-text-ellipsis ${
                      org.key === contextBar.key ? 'org-name-active' : ''
                    }`}
                  >
                    {org.name}
                  </div>
                </div>
              );
            })}
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: '8px',
            right: '0px',
            left: '0px',
          }}
        >
          <Divider />
          <div
            className="hall-of-org-navigation"
            onClick={contextBar.goToLinkFunction}
          >
            {contextBar.goToLinkText}
          </div>
        </div>
      </div>
    );
  }
  private getMenuItem(contextBar: any): React.ReactNode | undefined {
    if (contextBar) {
      return (
        <Menu.Item
          key={contextBar.key}
          style={{ float: 'left' }}
          disabled={contextBar.disabled}
          title={contextBar.name}
          onClick={contextBar.onClick}
        >
          <div className="navbarContextBar">
            <div className="navbarContextIcon">
              {!contextBar.iconSrc && !contextBar.iconInitials ? (
                <></>
              ) : (
                <Avatar shape="circle" size={40}>
                  {this.getContextBarIcon(contextBar)}
                </Avatar>
              )}
            </div>
            {contextBar.showRightNotch ? (
              <div className="contexBarArrow">
                <RightOutlined style={{ fontSize: '12px' }} />
              </div>
            ) : (
              <></>
            )}
            <p className="contextBarTitle">{contextBar.header}</p>
            <p className="contextBarSubTitle">{contextBar.name}</p>
          </div>
        </Menu.Item>
      );
    } else {
      return <></>;
    }
  }

  private getAvatar(): React.ReactNode | undefined {
    if (this.props.navbarUserInitials) {
      return (
        <Avatar shape="circle" size={40}>
          {this.props.navbarUserInitials}
        </Avatar>
      );
    } else {
      return <></>;
    }
  }

  private getNewDesignMenus(contextBar: any): React.ReactNode | undefined {
    if (this.props.newDesign) {
      return (
      <Popover
                  overlayClassName="navbar-header-menus-popover"
                  placement="bottomLeft"
                  title={contextBar.groupName}
                  content={this.popoverContent(contextBar)}
                  trigger="click"
                  visible={contextBar.header === 'Organization' ? this.state.isOrgVisible : this.state.isPropertyVisible}
                  onVisibleChange={contextBar.header === 'Organization' ? this.handleVisibleOrg : this.handleVisibleProperty}
                  id={contextBar.header + contextBar.name}
      >
                  <div className={`title_container title_epplisis ${contextBar.menuName === 'property' ? 'title_container_property' : ''}`}>
                      <span className="title">{contextBar.header}</span>
                      <span className="subTitle">
                        <Paragraph ellipsis={true}>{contextBar.name} </Paragraph>
                        </span>
                        {contextBar?.showRightNotch ? (
                          <div className="submenuArrow">
                            <ExpandMore style={{ fontSize: '12px' }} />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
      </Popover>
      );
    } else {
      return <></>;
    }
  }

  private getNavbarIcon(): React.ReactNode | undefined {
    if (this.props.navbarIcon) {
      return this.props.navbarIcon();
    } else {
      return undefined;
    }
  }

  private getUserProfile(): React.ReactNode | undefined {
    if (this.props.navbarUserInitials) {
      return this.getAvatar();
    }
    if (this.props.profileDropDown) {
      return this.props.profileDropDown;
    }
    return null;
  }

  private getContextBarIcon(
    contextBar: IContextBarProps,
  ): React.ReactNode | string | undefined {
    if (contextBar.iconSrc) {
      return contextBar.iconSrc();
    } else if (contextBar.iconInitials) {
      return contextBar.iconInitials;
    } else {
      return undefined;
    }
  }

  private getSubmenuIcon(
    submenuItem: ISubMenuProps,
  ): React.ReactNode | string | undefined {
    if (typeof submenuItem.icon === 'string') {
      return submenuItem.icon;
    } else if (submenuItem.icon) {
      return submenuItem.icon();
    } else {
      return undefined;
    }
  }
}
