import React, { Component, CSSProperties } from 'react';

export enum TextTypes {
  header = 'header',
  title = 'title',
  default = 'default',
}

export interface ITextProps {
  type?: TextTypes;
  bold?: boolean;
  children?: React.ReactNode;
  style?: CSSProperties;
  className?: string;
}

export default class Text extends Component<ITextProps> {
  public render() {
    return (
      <span className={this.getClassName()} style={this.props.style}>
        {this.getStyledChildren()}
      </span>
    );
  }

  private getClassName() {
    if (this.props.className) {
      return this.props.className;
    }
    let style = 'baseText';
    if (this.props.type) {
      switch (this.props.type) {
        case TextTypes.header:
          style = 'baseTextHeader';
          break;
        case TextTypes.title:
          style = 'baseTextTitle';
          break;
      }
    }
    return style;
  }

  private getStyledChildren() {
    if (this.props.bold) {
      const className = this.props.className ? this.props.className : 'bold';
      return (
        <span className={className}>
          {this.props.children}
        </span>
      );
    } else {
      return this.props.children;
    }
  }
}
