import { Row as AntRow } from 'antd';
import { RowProps } from 'antd/lib/row/';
import React from 'react';

const Row: React.FC<RowProps> = (props) => {
  return (
      <AntRow {...props} />
  );
};

export default Row;
