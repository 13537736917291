import { Breadcrumb as AntBreadcrumb } from 'antd';
import { BreadcrumbProps } from 'antd/lib/breadcrumb';
import React, { Component } from 'react';

import { AntdConfigProvider } from '../../config/AntdConfigProvider';

export default class Breadcrumb extends Component<BreadcrumbProps> {
  // tslint:disable-next-line:member-access
  static Item: any;

  constructor(props: BreadcrumbProps) {
    super(props);
  }

  public render() {
    return (
      <AntdConfigProvider>
        <AntBreadcrumb {...this.props} />
      </AntdConfigProvider>
    );
  }
}
