import { Tag as AntTag } from 'antd';
import { TagProps } from 'antd/lib/tag';
import React from 'react';

import { CloseGlyph } from '../icon/Icon.assets';
import CheckableTag from './CheckableTag';

import { AntdConfigProvider } from '../../config/AntdConfigProvider';

interface ITagProps extends TagProps {
  bold?: boolean;
}

const Tag = (props: ITagProps) => {
  return (
    <AntdConfigProvider>
      <AntTag
        {...props}
        className={`${props.bold ? 'bold' : ''} ${props.closable ? 'closable' : ''}`}
        closeIcon={props.closable ? <CloseGlyph /> : null}
      />
    </AntdConfigProvider>
  );
};

Tag.CheckableTag = CheckableTag;
export default Tag;
