import Rox from "rox-browser";

export const SETUP_ROLLOUT: string = "SETUP_ROLLOUT";
export const SETUP_ROLLOUT_SUCCESS: string = " SETUP_ROLLOUT_SUCCESS";
export const SETUP_ROLLOUT_ERROR: string = "SETUP_ROLLOUT_ERROR";

const featureFlags: any = {
  enablePartnerEditAndDelete: new Rox.Flag(false),
  showCountriesModule: new Rox.Flag(false),
  enableLanguagesModule: new Rox.Flag(false),
  isSystemOnMaintainence: new Rox.Flag(false),
  enableCropsModule: new Rox.Flag(false),
};
export const getFeatureFlags = (context: any) => {
  const targetObj: any = {};
  Object.keys(featureFlags).forEach((flagKey: string) => {
    if (featureFlags[flagKey]._type === "boolean") {
      targetObj[flagKey] = featureFlags[flagKey].isEnabled(context);
    } else {
      targetObj[flagKey] = featureFlags[flagKey].getValue(context);
    }
  });
  return targetObj;
};

export async function initRollout(configs: any) {
  const { email } = configs || {};
  const ROLLOUT_KEY: any = process.env.REACT_APP_ROLLOUT_KEY;
  if (email != undefined) {
    Rox.setCustomStringProperty("allowedEmail", email);
  }
  Rox.register("", featureFlags);
  await Rox.setup(ROLLOUT_KEY, {});
  return rolloutSuccess(getFeatureFlags(featureFlags));
}

export function rolloutSuccess(payload: any) {
  return {
    type: SETUP_ROLLOUT_SUCCESS,
    payload: payload,
  };
}
export function rolloutError(error: any) {
  return {
    type: SETUP_ROLLOUT_ERROR,
    error,
  };
}
