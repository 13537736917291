import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import get from 'lodash/get';
import shpwrite from 'shp-write';
import tokml from 'tokml';

/**
 * collect geometry data
 * @param selectedFields
 * @returns
 */
const getFeatures = (selectedFields: any) => {
  const features = [];
  for (const area of selectedFields) {
    if (area.geometry) {
      const properties = get(area, 'properties', {});
      properties.name = area.name;
      properties.declared_area = area.declared_area;
      properties.id = area.id;
      properties.event_date = area.event_date;
      features.push({
        type: 'Feature',
        properties,
        geometry: get(area, 'geometry', {}),
      });
    }
  }
  return features;
};

/**
 *
 * @param {*} zip
 * @param {*} property
 * @param {*} data
 * generate geojson file
 */
const getGeojsonFile = (zip: any, property: any, data: any) => {
  zip.file(`${property.name}.geojson`, JSON.stringify(data, null, 4));
};

/**
 *
 * @param {*} zip
 * @param {*} property
 * @param {*} data
 * generate geojson file
 */
const getKmlFile = (zip: any, property: any, data: any) => {
  zip.file(`${property.name}.kml`, tokml(data));
};

/**
 *
 * @param {*} property
 * @param {*} data
 * generate geojson file
 */
const getShapeFile = (property: any, data: any) => {
  const options: any = {
    folder: property.name,
    types: {
      point: 'mypoints',
      polygon: 'mypolygons',
      line: 'mylines',
    },
  };
  shpwrite.download(data, options);
};

/**
 *
 * @param zip
 * @param property
 * @param labels
 */
const downloadZipManually = (zip: any, property: any, labels: any) => {
  let error: any = null;
  zip.generateAsync({ type: 'blob' }).then((content: any) => {
    saveAs(content, `${property.name}.zip`);
  }).catch((err: any) => {
    error = err;
  });
  return error;
};

/**
 *
 * @param {*} selectedFields
 * @param {*} property
 * @param {*} t
 * download shape files
 */
const downloadShape = (selectedFields: any, property: any, labels: any, downloadAs: string = '') => {
  let error: any = null;
  if (selectedFields.length > 0) {
    try {
      const features = getFeatures(selectedFields);
      if (features.length > 0) {
        const data = {
          type: 'FeatureCollection',
          features,
        };
        const zip = new JSZip();
        switch (downloadAs) {
          case 'kml':
            getKmlFile(zip, property, data);
            downloadZipManually(zip, property, labels);
            break;
          case 'shape':
            getShapeFile(property, data);
            break;
          default:
            getGeojsonFile(zip, property, data);
            downloadZipManually(zip, property, labels);
        }
      } else {
        error = new Error(labels.no_geometry_found);
      }
    } catch (err) {
      error = err;
    }
  } else {
    error = new Error(labels.no_field_selected);
  }
  return error;
};

export default downloadShape;
