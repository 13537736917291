import { Button as AntButton } from 'antd';
import { ButtonProps, ButtonShape as AntdShapeType, ButtonType as AntButtonType } from 'antd/lib/button';
import React, { Component, CSSProperties } from 'react';

import { AntdConfigProvider } from '../../config/AntdConfigProvider';

export enum ButtonType {
  primary = 'primary',
  success = 'success',
  warn = 'warn',
  danger = 'danger',
  info = 'info',
  ghost = 'ghost',
  link = 'link',
  dashed = 'dashed',
  outline = 'outline',
  overlay = 'overlay',
  default = 'default',
}

export type SizeType = 'small' | 'middle' | 'large' | undefined;
export type ShapeType = 'circle' | 'circle-outline' | 'round' | undefined;
export type ColorType = 'green' | 'blue' | undefined;

export interface IButtonProps extends Omit<ButtonProps, 'type'|'shape'> {
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  type?: ButtonType;
  disabled?: boolean;
  style?: CSSProperties;
  icon?: React.ReactNode;
  size?: SizeType;
  color?: ColorType;
  shape?: ShapeType;
  danger?: boolean;
  ghost?: boolean;
  block?: boolean;
  loading?: boolean;
}

export default class Button extends Component<IButtonProps> {
  public render() {
    return (
      <AntdConfigProvider>
        <AntButton
          {...this.props}
          type={this.getAntdType()}
          className={this.getClassName()}
          onClick={this.props.onClick}
          style={this.props.style}
          shape={this.getAntdShape()}
        >
          {this.props.children}
        </AntButton>
      </AntdConfigProvider>
    );
  }

  private getAntdType(): AntButtonType {
    if (this.props.disabled) {
      return 'disabled' as AntButtonType;
    }
    switch (this.props.type) {
      case ButtonType.primary:
        return 'primary' as AntButtonType;
      case ButtonType.success:
        return 'primary' as AntButtonType;
      case ButtonType.warn:
        return 'danger' as AntButtonType;
      case ButtonType.danger:
        return 'danger' as AntButtonType;
      case ButtonType.info:
        return 'default';
      case ButtonType.ghost:
        return 'ghost';
      case ButtonType.link:
        return 'link';
      case ButtonType.default:
        return 'default' as AntButtonType;
      default:
        return 'default';
    }
  }

  private getAntdShape(): AntdShapeType {
    if (this.props.shape === 'circle-outline') {
      return 'circle';
    }
    return this.props.shape as AntdShapeType;
  }

  private getClassName() {
    let className = '';
    if (this.props.type === 'primary') {
      className = 'primaryButton';
    } else if (this.props.type === 'outline') {
      className = 'outlineButton';
    } else if (this.props.type === 'danger') {
      className = 'dangerButton';
    } else if (this.props.type === 'overlay') {
      className = 'overlayButton';
    } else if (this.props.type === 'dashed') {
      className = 'dashedButton';
    } else if (this.props.type === 'link') {
      className = this.props.color === 'blue' ? 'blueLinkButton' : 'greenLinkButton';
    } else {
      className = 'defaultButton';
    }
    return this.props.className ? `${className} ${this.props.className}` : className;
  }
}
