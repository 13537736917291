import { Skeleton as AntSkeleton } from 'antd';
import { SkeletonProps } from 'antd/lib/skeleton';
import React from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';

const Skeleton: React.FC<SkeletonProps> & { Button?: any} & { Input?: any} & {Avatar?: any} = (props) => {
  return (
    <AntdConfigProvider>
      <AntSkeleton {...props} />
    </AntdConfigProvider>
  );
};

Skeleton.Button = AntSkeleton.Button;
Skeleton.Input = AntSkeleton.Input;
Skeleton.Avatar = AntSkeleton.Avatar;

export default Skeleton;
