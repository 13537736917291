import { Radio as AntRadio } from 'antd';
import { RadioProps } from 'antd/lib/radio';
import React, { Component } from 'react';

import { AntdConfigProvider } from '../../config/AntdConfigProvider';

export default class Radio extends Component<RadioProps> {
  // tslint:disable-next-line:member-access
  static Group: any;
  // tslint:disable-next-line:member-access
  static Button: any;

  public constructor(props: RadioProps) {
    super(props);
  }

  public render() {
    return (
      <AntdConfigProvider>
        <AntRadio {...this.props} />
      </AntdConfigProvider>
    );
  }
}
