import { Dropdown as AntDropdown } from 'antd';
import { DropDownProps } from 'antd/lib/dropdown/';
import React from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';
import DropdownButton from './DropdownButton';

interface IDropdownInterface extends React.FC<DropDownProps> {
  Button?: typeof DropdownButton;
}

const Dropdown: IDropdownInterface = (props) => {
  return (
    <AntdConfigProvider>
      <AntDropdown {...props} />
    </AntdConfigProvider>
  );
};

export default Dropdown;
