import { Input as AntInput } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import React from 'react';

import { AntdConfigProvider } from '../../config/AntdConfigProvider';

export interface ITextAreaProps extends TextAreaProps {
  caption?: string;
  label?: string;
}

const TextArea: React.FC<ITextAreaProps> = (props) => {
  return (
    <AntdConfigProvider>
      { props.label && (
        <div className="syt-antd-input-label">
          <label title={props.label}>{props.label}</label>
        </div>
      )}
      <AntInput.TextArea {...props} />
      { props.caption && (
        <div className="syt-antd-input-caption">
          <label title={props.caption}>{props.caption}</label>
        </div>
      )}
    </AntdConfigProvider>
  );
};

export default TextArea;
