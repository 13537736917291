
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

import { GeoJsonToSvg, Popover } from './../index';
import TimelineVersioningIcon from './images/timelineVersioningIcon.svg';

import {
  arrayOfDaysInEachMonth,
  months,
  monthsShorts,
} from './util';

const DATE_FORMAT = 'YYYY/MM/DD';
interface IMonthProps {
  year: number;
  length: number;
  WindowWidth: number;
}
function MonthTab({ year, length, WindowWidth }: IMonthProps) {
  const date = new Date();
  const dateMoment = moment(date);
  const CurrentMonth = dateMoment.format('M');
  const CurrentYear = date.getFullYear();

  const activeMonth = (month: number) => {
    if (year === CurrentYear && month === +CurrentMonth) {
      return true;
    } else {
      return false;
    }
  };

  const handleMonths = () => {
    if (length > 3) {
      return monthsShorts;
    }
    return months;
  };

  return (
    <div className="month_tab">
      {handleMonths().map((month, index) => {
        return (
          <div
            style={{
              width: WindowWidth / 12,
              backgroundColor: activeMonth(index + 1) ? '#E99921' : '',
              borderTopLeftRadius: activeMonth(index + 1) ? '10px' : '',
              borderTopRightRadius: activeMonth(index + 1) ? '10px' : '',
            }}
            key={index}
            className="month_tab_item"
          >
            {month}
          </div>
        );
      })}
    </div>
  );
}
interface IGridProps {
  children: any;
  WindowWidth: number;
}

function Grid({ children, WindowWidth }: IGridProps) {
  return (
    <div style={{ width: WindowWidth }} className="grid_tab">
      <div className="grid_child">{children}</div>
      {months.map((month, index) => (
        <div
          style={{ width: WindowWidth / 12 }}
          key={index}
          className="grid_tab_item"
        />
      ))}
    </div>
  );
}
interface IFieldTimeLineProps {
  year: number;
  fieldSeasons: any[];
  WindowWidth: number;
  field: any;
  fieldVersioning: any[];
}

function FieldTimeLine({
  year,
  WindowWidth,
  field,
  fieldSeasons,
  fieldVersioning,
}: IFieldTimeLineProps) {
  return (
    <div className="field_timeline">
      {fieldSeasons.map((season: any, index: number) => {
        return (
          <Season
            key={index}
            season={season}
            WindowWidth={WindowWidth}
            year={year}
            field={field}
          />
        );
      })}
      {fieldVersioning.map((fieldVersion, index) => {
        return (
          <Fieldbar
            fieldVersion={fieldVersion}
            key={index}
            WindowWidth={WindowWidth}
            year={year}
            field={field}
          />
        );
      })}
    </div>
  );
}

interface ISeasonProps {
  season: any;
  WindowWidth: number;
  year: number;
  field: any;
}

function Season({ season, WindowWidth, year, field }: ISeasonProps) {
  const startDateMoment = moment(season.startDate, DATE_FORMAT);
  const endDateMoment = moment(season.endDate, DATE_FORMAT);

  const startDateYear: number = parseInt(startDateMoment.format('YYYY'), 10);
  const startDateMonth: number = parseInt(startDateMoment.format('M'), 10);
  const startDateDay: number = parseInt(startDateMoment.format('D'), 10);

  const endDateYear: number = parseInt(endDateMoment.format('YYYY'), 10);
  const endDateMonth: number = parseInt(endDateMoment.format('M'), 10);
  const endDateDay: number = parseInt(endDateMoment.format('D'), 10);

  const DayWidth = WindowWidth / 12 / arrayOfDaysInEachMonth(startDateYear)[startDateMonth - 1];

  const SeasonStartDatePosition =
    (WindowWidth / 12) * (startDateMonth - 1) + DayWidth * startDateDay;
  const SeasonEndDatePosition =
    (WindowWidth / 12) * (endDateMonth - 1) + DayWidth * endDateDay;

  const yearDifference = endDateYear - startDateYear;

  const SeasonWidth =
    SeasonEndDatePosition -
    SeasonStartDatePosition +
    yearDifference * WindowWidth;

  function seasonColor() {
    if (
      moment(new Date()).isBetween(
        startDateMoment,
        endDateMoment,
        undefined,
        '[]',
      )
    ) {
      return '#90D6AA';
    } else if (endDateMoment.isBefore(moment(new Date()))) {
      return '#C2C7D0';
    } else if (startDateMoment.isAfter(moment(new Date()))) {
      return '#82CFFF';
    }
  }

  function seasonStatus() {
    if (moment(new Date()).isBetween(startDateMoment, endDateMoment)) {
      return 'ACTIVE';
    } else if (endDateMoment.isBefore(moment(new Date()))) {
      return 'INACTIVE';
    } else if (startDateMoment.isAfter(moment(new Date()))) {
      return 'FUTURE';
    }
  }

  if (year !== +startDateYear && year !== +endDateYear) {
    return null;
  }
  function check() {
    if (year !== +startDateYear) {
      return SeasonEndDatePosition - SeasonWidth;
    }
    return SeasonStartDatePosition;
  }

  const SeasonStatus = seasonStatus();

  return (
    <>
      <Popover
        overlayClassName="page-info-popup"
        // tslint:disable-next-line: jsx-no-lambda
        content={() => SeasonModel({ SeasonStatus, season, field })}
        overlayInnerStyle={{
          borderRadius: '4px',
          width: '275px',
        }}
      >
        <div
          style={{
            left: check(),
            width: SeasonWidth + 1,
            background: seasonColor(),
          }}
          className="season"
        >
          <p>{season?.seasonName.toUpperCase()}</p>
        </div>
      </Popover>
    </>
  );
}

interface IFieldbarProps {
  fieldVersion: any;
  WindowWidth: number;
  year: number;
  field: any;
}

function Fieldbar({ fieldVersion, WindowWidth, year, field }: IFieldbarProps) {
  const fieldDateMoment = moment(fieldVersion.f_version_date, DATE_FORMAT);
  const fieldDateYear: number = parseInt(fieldDateMoment.format('YYYY'), 10);
  const fieldDateMonth: number = parseInt(fieldDateMoment.format('M'), 10);
  const fieldDateDay: number = parseInt(fieldDateMoment.format('D'), 10);
  const DayWidth = WindowWidth / 12 / arrayOfDaysInEachMonth(fieldDateYear)[fieldDateMonth - 1];
  const fieldDatePosition =
    (WindowWidth / 12) * (fieldDateMonth - 1) + DayWidth * fieldDateDay;

  if (year !== +fieldDateYear) {
    return null;
  }

  return (
    <>
      <Popover
        overlayClassName="page-info-popup"
        // tslint:disable-next-line: jsx-no-lambda
        content={() => FieldVersionModel({ fieldVersion, field })}
        overlayInnerStyle={{
          borderRadius: '4px',
          width: '275px',
          height: '144px',
        }}
        placement="topLeft"
      >
        <div
          style={{ left: fieldDatePosition }}
          className="fieldbar"
        >
          <TimelineVersioningIcon />
        </div>
      </Popover>
    </>
  );
}

interface IFieldVersionModel {
  fieldVersion: any;
  field: any;
}
function FieldVersionModel({ fieldVersion, field }: IFieldVersionModel): JSX.Element {
  const momentDate = moment(fieldVersion.f_version_date, DATE_FORMAT);
  const Date = momentDate.format('DD MMM, YYYY');

  return (
    <div className="field_version_model">
      <div className="model_title">
        <h3>Edition</h3>
        <p>{Date}</p>
      </div>
      <div className="model_content">
        <div className="pre_container">
          <p>Previous version</p>
          <div className="pre_version">
            <div className="img">
            <GeoJsonToSvg geojson={field} width="100%" height="100%" />
            </div>
            <div>
              <p>Field 05</p>
              <p>20,80 ha</p>
            </div>
          </div>
        </div>
        <div className="next_container">
          <p>Next version</p>
          <div className="next_version">
            <div className="img">
            <GeoJsonToSvg geojson={field} width="100%" height="100%" />
            </div>
            <div>
              <p>Field 05</p>
              <p>20,80 ha</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface ISeasonModelProps {
  SeasonStatus: any;
  season: any;
  field: any;
}

function SeasonModel({ SeasonStatus, season, field }: ISeasonModelProps) {

  const SeasonColor = () => {
    if (SeasonStatus === 'ACTIVE') {
      return '#14803C';
    } else if (SeasonStatus === 'INACTIVE') {
      return '#696F88';
    } else if (SeasonStatus === 'FUTURE') {
      return '#0071CD';
    }
  };

  return (
    <div className="season_model">
      <div className="season_title">
        <div className="seasonImg">
          <div className="lazyload">
              <GeoJsonToSvg geojson={field} width="100%" height="100%" />
          </div>
          <div>
            <h3>{season?.seasonName.toUpperCase()}</h3>
            <p>{season?.area} ha</p>
          </div>
        </div>
        <p className="active" style={{ backgroundColor: SeasonColor() }}>
          {SeasonStatus}
        </p>
      </div>
      <div className="season_content">
        <div className="content">
          <div>
            <p>Crop cycle</p>
            <p>{season?.cropCycle}</p>
          </div>
          <div>
            <p>Crop cycle date</p>
            <p>
            {season?.cropCycleDate?.[0]} <span>|</span> {season?.cropCycleDate?.[1]}
            </p>
          </div>
        </div>
        {
        SeasonStatus !== 'FUTURE' && (
          <div className="content">
          <div>
            <p>Planting date</p>
            <p>{season?.plantingDate}</p>
          </div>
          <div>
            <p>Variety</p>
            <p>{season?.variety}</p>
          </div>
        </div>
       )
       }
       {
        SeasonStatus !== 'FUTURE' && (
        <div className="content">
         <div>
           <p>Emergency date</p>
           <p>{season?.emergencyDate}</p>
         </div>
         <div>
           <p>Harvest date</p>
           <p>{season?.harvestDate}</p>
         </div>
       </div>
       )
       }
      </div>
      <div className="season_footer">
        <p>Click on the field to edit dates</p>
      </div>
    </div>
  );
}
interface IActiveDateProps {
  WindowWidth: number;
  year: number;
  showyears: number[];
}

function ActiveDate({ WindowWidth, showyears }: IActiveDateProps) {
  const today = moment(new Date());
  const activeDateMoment = moment(today);
  const activeDateYear: number = parseInt(activeDateMoment.format('YYYY'), 10);
  const activeDateMonth: number = parseInt(activeDateMoment.format('M'), 10);
  const activeDateDay: number = parseInt(activeDateMoment.format('D'), 10);
  const DayWidth =
    WindowWidth / 12 / arrayOfDaysInEachMonth(activeDateYear)[activeDateMonth - 1];
  const pastyears = showyears.indexOf(+activeDateYear);

  const activeDatePosition =
    (WindowWidth / 12) * (activeDateMonth - 1) +
    DayWidth * activeDateDay +
    pastyears * WindowWidth;

  return <div style={{ left: activeDatePosition }} className="activeDate" />;
}

function ScrollPosition(WindowWidth: number, showyears: number[]) {
  const today = moment(new Date());
  const activeDateMoment = moment(today);
  const activeDateYear: number = parseInt(activeDateMoment.format('YYYY'), 10);
  const activeDateMonth: number = parseInt(activeDateMoment.format('M'), 10);
  const activeDateDay: number = parseInt(activeDateMoment.format('D'), 10);
  const DayWidth =
    WindowWidth / 12 / arrayOfDaysInEachMonth(activeDateYear)[activeDateMonth - 1];
  const pastyears = showyears.indexOf(+activeDateYear);

  return (
    (WindowWidth / 12) * (activeDateMonth - 1) +
    DayWidth * activeDateDay +
    pastyears * WindowWidth
  );
}

const PropertyTimeline = (props: any) => {
  const { fields, fieldVersioning, seasons } = props;
  const [showyears, setShowyears] = useState([2020, 2021, 2022]);
  const containerRef = useRef<HTMLDivElement>(document.createElement('div'));
  const [WindowWidth, setWindowWidth] = useState<number>(
    containerRef?.current?.offsetWidth * 0.68,
  );
  const [scrollPosition, setScroll] = useState<number>(700);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowWidth(containerRef.current.offsetWidth * 0.68);
    });
  }, []);

  function handleZoom() {
    if (showyears.length !== 3) {
      setShowyears([2020, 2021, 2022]);
      setWindowWidth(containerRef?.current?.offsetWidth * 0.68);
    } else {
      setShowyears([2018, 2019, 2020, 2021, 2022, 2023, 2024]);
      setWindowWidth(containerRef?.current?.offsetWidth * 0.2);
    }
  }

  const handleHeight = () => {
    return 70 + fields.length * 50;
  };

  const currentyear = new Date().getFullYear();

  const scroll = (scrollOffset: number) => {
    setTimeout(() => {
      containerRef.current.scrollLeft = scrollOffset;
    }, 100);
  };

  useEffect(() => {
    setWindowWidth(containerRef?.current?.offsetWidth * 0.68);
    setScroll(ScrollPosition(WindowWidth, showyears));
    setTimeout(() => {
      scroll(scrollPosition);
    }, 100);
  }, []);

  const handleBgColor = (year: number) => {
    const check = +currentyear % 2;
    if (year % 2 === check) {
      return '#ffff';
    }
    return '#F3F4F6';
  };

  return (
    <>
      <div
        ref={containerRef}
        style={{ height: `${handleHeight()}px` }}
        className="field-timeline-container"
      >
        {showyears.map((year, index) => {
          return (
            <div
              key={index}
              style={{
                width: WindowWidth,
                backgroundColor: handleBgColor(year),
              }}
            >
              <p style={{ textAlign: 'center' }} onClick={handleZoom}>
                {year}
              </p>
              <ActiveDate
                WindowWidth={WindowWidth}
                year={year}
                showyears={showyears}
              />
              <MonthTab
                year={year}
                length={showyears.length}
                WindowWidth={WindowWidth}
              />
              {fields.map((field: any, ind: number) => {
                return (
                  <Grid key={ind} WindowWidth={WindowWidth}>
                    <FieldTimeLine
                      year={year}
                      fieldSeasons={seasons}
                      WindowWidth={WindowWidth}
                      field={field}
                      fieldVersioning={fieldVersioning}
                    />
                  </Grid>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PropertyTimeline;
