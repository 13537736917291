import { Slider as AntSlider } from 'antd';
import { SliderBaseProps } from 'antd/lib/slider';
import React from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';

const Slider: React.FC<SliderBaseProps> = (props) => {
  return (
    <AntdConfigProvider>
      <AntSlider {...props} />
    </AntdConfigProvider>
  );
};

export default Slider;
