import { Steps as AntSteps } from 'antd';
import { StepsProps } from 'antd/lib/steps';
import React from 'react';

import { AntdConfigProvider } from '../../config/AntdConfigProvider';

const Steps: React.FC<StepsProps> & {Step?: any} = (props) => {
  return (
    <AntdConfigProvider>
      <AntSteps {...props} />
    </AntdConfigProvider>
  );
};

Steps.Step = AntSteps.Step;

export default Steps;
