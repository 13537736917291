import { default as AntTitle } from 'antd/lib/typography/Title';
import React, { Component, CSSProperties } from 'react';
import ICopyable from './ICopyable';
import IEditable from './IEditable';

import { AntdConfigProvider } from '../../config/AntdConfigProvider';

export type LevelType = 1 | 2 | 3 | 4 | undefined;

export interface ITitleProps {
  className?: string;
  code?: boolean;
  copyable?: boolean | ICopyable;
  delete?: boolean;
  disabled?: boolean;
  editable?: boolean | IEditable;
  ellipsis?: boolean;
  level?: LevelType;
  mark?: boolean;
  underline?: boolean;
  strong?: boolean;
  style?: CSSProperties;
  onChange?: (arg0: string) => void;
}
export class Title extends Component<ITitleProps> {

  public render() {
    return (
      <AntdConfigProvider>
        <AntTitle {...this.props} className={`cwsTypography ${this.props.className ?? ''}`}>{this.props.children}</AntTitle>
      </AntdConfigProvider>
    );
  }
}
export default Title;
