import { Drawer as AntDrawer } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import React from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';

const Drawer: React.FC<DrawerProps> = (props) => {
  return (
    <AntdConfigProvider>
      <AntDrawer {...props} />
    </AntdConfigProvider>
  );
};

export default Drawer;
