import { Pagination as AntPagination } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';
import React from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';

const Pagination: React.FC<PaginationProps> = (props) => {
  return (
    <AntdConfigProvider>
      <AntPagination {...props} />
    </AntdConfigProvider>
  );
};

export default Pagination;
