import { Tree as AntTree } from 'antd';
import { TreeProps } from 'antd/lib/tree';
import React from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';

const Tree: React.FC<TreeProps> = (props) => {
  return (
    <AntdConfigProvider>
      <AntTree {...props} />
    </AntdConfigProvider>
  );
};

export default Tree;
