import React from 'react';

import { Modal as AntModal } from 'antd';
import { ModalFuncProps, ModalProps } from 'antd/lib/modal';

import { AntdConfigProvider } from '../../config/AntdConfigProvider';
import { CheckCircleGreen, ErrorOutline, InfoBlue, ReportProblem } from '../icon/Icon.assets';

const OUTLINED_BUTTON_PROP = {
  className: 'outlined-button',
};

interface IModalProps extends ModalProps {
  children?: React.ReactNode;
}

const Modal = (props: IModalProps) => {
  return (
    <AntdConfigProvider>
      <AntModal {...props} />
    </AntdConfigProvider>
  );
};

Modal.confirm = ({ ...props }: ModalFuncProps): any => {
  AntModal.confirm({
    icon: <CheckCircleGreen/>,
    cancelButtonProps: OUTLINED_BUTTON_PROP,
    ...props,
  });
};

Modal.error = ({ ...props }: ModalFuncProps): any => {
  AntModal.error({
    icon: <ErrorOutline/>,
    okButtonProps: {
      className: 'danger-button',
    },
    cancelButtonProps: OUTLINED_BUTTON_PROP,
    ...props,
  });
};

Modal.info = ({ ...props }: ModalFuncProps): any => {
  AntModal.info({
    icon: <InfoBlue/>,
    okButtonProps: OUTLINED_BUTTON_PROP,
    ...props,
  });
};

Modal.success = ({ ...props }: ModalFuncProps): any => {
  AntModal.success({
    icon: <CheckCircleGreen/>,
    okButtonProps: OUTLINED_BUTTON_PROP,
    ...props,
  });
};

Modal.warning = ({ ...props }: ModalFuncProps): any => {
  AntModal.warning({
    icon: <ReportProblem/>,
    ...props,
  });
};

export default Modal;
