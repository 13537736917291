import { Select as AntSelect } from 'antd';
import { SelectProps } from 'antd/lib/select';
import React from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';
import { ExpandMore } from '../icon/Icon.assets';

const Select: React.FC<SelectProps<any>> & { Option?: any} & { OptGroup?: any } = (props) => {
  return (
    <AntdConfigProvider>
      {props.loading ? <AntSelect {...props} /> : <AntSelect suffixIcon={ExpandMore} {...props} />}
    </AntdConfigProvider>
  );
};

Select.Option = AntSelect.Option;
Select.OptGroup = AntSelect.OptGroup;

export default Select;
