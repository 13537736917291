import { Table } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React from "react";
import { useTranslation } from "react-i18next";
export const CommonTableComponent = ({
  columns,
  dataSource,
  total,
  loading,
  pageSizeOptions = ["10", "20", "30", "40"],
  defaultPageSize = 10,
  setCurrentPageDetails = null,
  current,
}: {
  columns: any;
  dataSource: any;
  total?: number;
  loading?: boolean;
  pageSizeOptions?: Array<string>;
  defaultPageSize?: number;
  setCurrentPageDetails?: any;
  current?: number;
}) => {
  const { t } = useTranslation();
  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <a>{t("Previous")} </a>;
    }
    if (type === "next") {
      return <a>{t("Next")} </a>;
    }
    return originalElement;
  };
  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      bordered={true}
      size="small"
      loading={loading}
      pagination={{
        onChange(page, pageSize) {
          if (setCurrentPageDetails) {
            setCurrentPageDetails({ currentPageNumber: page, size: pageSize });
          }
        },
        total,
        current,
        itemRender,
        pageSizeOptions,
        style: { color: "green" },
        defaultPageSize,
        showSizeChanger: true,
      }}
    />
  );
};
