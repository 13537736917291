import { Select } from "antd";
import startCase from "lodash/startCase";
import React from "react";
import { Form } from "syngenta-digital-cropwise-react-ui-kit";

const FormSelect = (props: any) => {
  const {
    label,
    name,
    rules,
    defaultValue,
    itemClassName,
    style,
    items,
    valueKey,
    bordered,
    maxTagCount,
    mode,
    onChange,
    onFocus,
    disabled,
    onDropdownVisibleChange,
    onClick,
  } = props;
  return (
    <Form.Item
      label={label || startCase(name)}
      name={name}
      className={itemClassName}
      rules={rules}
      initialValue={defaultValue}
    >
      <Select
        size="large"
        dropdownStyle={props.dropdownStyle}
        data-cy={`${props.name}-select`}
        className="selectdrop"
        placeholder={props?.placeholder ?? `Select`}
        style={style}
        bordered={bordered ?? false}
        dropdownMatchSelectWidth={props?.dropdownMatchSelectWidth ?? false}
        maxTagCount={maxTagCount}
        mode={mode}
        onChange={onChange}
        onFocus={onFocus}
        defaultValue={props?.defaultValue}
        onClick={onClick}
        disabled={disabled}
        onDropdownVisibleChange={onDropdownVisibleChange}
      >
        {items?.length &&
          items.map((item: any) => {
            const value =
              typeof item === "object"
                ? { val: item.id, display: item[valueKey || "value"] }
                : { val: item, display: item };
            return (
              <Select.Option key={value.val} value={value.val}>
                {value.display}
              </Select.Option>
            );
          })}
      </Select>
    </Form.Item>
  );
};
const FormHandler: any = () => {};

FormHandler.Select = FormSelect;

export { FormHandler };
