import tj from '@mapbox/togeojson';
import JSZip from 'jszip';
import proj4 from 'proj4';
import shp from 'shpjs';
import * as topojson from 'topojson-client';
import { parse } from 'wellknown';
import { getExtension, getFileName } from './getGeojsonInput';

const fileConverter: any = {};

fileConverter.getCorrectResponse = (geojson: any, fileName: string) => {
  let response = geojson;
  if (geojson.length > 1) {
    for (const val of geojson) {
      if ((!('bbox' in val)) && 'type' in val && val.type === 'FeatureCollection') {
        response = val;
        break;
      }
    }
  }
  return {
    response,
    fileName: getFileName(fileName),
  };
};

fileConverter.fromKmlToGeojson = (file: any, callback: any) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const data = e?.target?.result || '';
    const parser = new DOMParser();
    const kml = parser.parseFromString(data.toString(), 'application/xml');
    const geojson = tj.kml(kml);
    callback(fileConverter.getCorrectResponse(geojson, file.name));
  };
  reader.readAsText(file);
};

fileConverter.fromWktToGeojson = (file: any, callback: any) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const data = e?.target?.result || '';
    const geojson = parse(data.toString());
    callback({
      response: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: Object.assign({}, geojson),
          },
        ],
      },
      fileName: getFileName(file.name),
    });
  };
  reader.readAsText(file);
};

fileConverter.fromTopojsonToGeojson = (file: any, callback: any) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const str: any = e?.target?.result;
    const data = JSON.parse(str);
    const geojson = topojson.feature(data, data.objects.collection);
    callback(fileConverter.getCorrectResponse(geojson, file.name));
  };
  reader.readAsText(file);
};

// projectionType = 2154 => this is lambert-96
// projectionType = 5842 => this is america-5842
fileConverter.changeProjection = (geojson: any, projectionType: string) => {
  if (projectionType === '2154' || projectionType === '5842') {
    proj4.defs('EPSG:2154', '+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
    proj4.defs('EPSG:5842', '+proj=tmerc +lat_0=0 +lon_0=12 +k=0.9996 +x_0=500000 +y_0=10000000 +datum=WGS84 +units=m +no_defs');
    geojson.features.map((feature: any) => {
      const coordinates = feature.geometry.coordinates;
      feature.geometry.coordinates = coordinates.map((rows: any) => {
        return rows.map((row: any) => {
          return proj4(`EPSG:${projectionType}`, 'WGS84', [row[0], row[1]]);
        });
      });
      return feature;
    });
    return geojson;
  } else {
    return geojson;
  }
};

fileConverter.fromZipToGeojson = (file: any, callback: any, projectionType: string, checkInvalidExt: boolean) => {
  const allowedExt = ['shp', 'prj', 'dbf', 'shx', 'cpg'];
  const mandatoryExt = ['shp', 'dbf', 'shx'];
  const providedExt: any = [];
  const invalidExt: any = [];
  JSZip.loadAsync(file).then((zip) => {
    zip.forEach((relativePath, zipEntry) => {
      const ext = getExtension(zipEntry.name, false);
      if (ext) {
        providedExt.push(ext);
        if (allowedExt.includes(ext) === false) {
          invalidExt.push(ext);
        }
      }
    });

    const missingMandatoryExt = mandatoryExt.filter((ext) => {
      return !providedExt.includes(ext);
    });

    if (missingMandatoryExt.length > 0) {
      callback(new Error(`Missing following mandatory files - ${missingMandatoryExt.toString()}`));
    } else if (invalidExt.length > 0 && checkInvalidExt === true) {
      callback(new Error(`Folder contains following invalid files - ${invalidExt.toString()}`));
    } else {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data: any = e?.target?.result || [];
        shp(data).then((geojson: any) => {
          const correctResponse = fileConverter.getCorrectResponse(geojson, file.name);
          callback({
            response: fileConverter.changeProjection(correctResponse.response, projectionType),
            fileName: getFileName(file.name),
          });
        }).catch((error) => {
          callback(error);
        });
      };
      reader.readAsArrayBuffer(file);
    }
  }).catch((error) => {
    callback(error);
  });
};

fileConverter.convertJson = (file: any, callback: any) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const str: any = e?.target?.result;
    const geojson = JSON.parse(str);
    callback(fileConverter.getCorrectResponse(geojson, file.name));
  };
  reader.readAsText(file);
};

fileConverter.fromGeojsonToGeojson = (file: any, callback: any) => {
  fileConverter.convertJson(file, callback);
};

fileConverter.fromJsonToGeojson = (file: any, callback: any) => {
  fileConverter.convertJson(file, callback);
};

fileConverter.convertShapeFiles = (file: any, callback: any, projectionType: string) => {
  const zip = new JSZip();
  for (const val of file) {
    zip.file(val.name, val);
  }
  zip.generateAsync({ type: 'arraybuffer' }).then((content: any) => {
    shp(content).then((geojson: any) => {
      callback(fileConverter.getCorrectResponse(geojson, file[0].name));
    }).catch((error) => {
      callback(error);
    });
  }).catch((error) => {
    callback(error);
  });
};

export const getGeojson = (file: any, callback: any, projectionType: string = 'WGS84', checkInvalidExt: boolean = false, shapeFiles: boolean = false) => {
  if (shapeFiles) {
    fileConverter.convertShapeFiles(file, callback, projectionType);
  } else {
    let ext = getExtension(file.name, false).toLowerCase();
    ext = `${ext.charAt(0).toUpperCase()}${ext.slice(1)}`;
    if (fileConverter[`from${ext}ToGeojson`]) {
      fileConverter[`from${ext}ToGeojson`](file, callback, projectionType, checkInvalidExt);
    } else {
      callback(new Error('Invalid File'));
    }
  }
};
