import { Popover as AntPopover } from 'antd';
import { PopoverProps } from 'antd/lib/popover';
import React, { FC, ReactElement } from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';

interface IPopoverProps extends PopoverProps {
  dark?: boolean;
}

const Popover: FC<IPopoverProps> = ({ ...props }): ReactElement => {
  return (
    <AntdConfigProvider>
      <AntPopover overlayClassName={props.dark ? 'dark' : ''} {...props} />
    </AntdConfigProvider>
  );
};

export default Popover;
