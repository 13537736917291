import JSZip from 'jszip';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Empty, List, Select, Tooltip, Upload } from './../../index';
import { DeleteOutlined, InboxOutlined } from './../icons';
import { ConfirmPopup } from './ConfirmPopup';
import ProjFileMissingWarning from './images/report_problem_24px.svg';

const { Dragger } = Upload;
let tempFileList: any = [];
const NON_SHP_EXT = ['.json', '.geojson', '.kml', '.zip', '.topojson', '.wkt'];
const SHP_EXT = ['.shp', '.prj', '.shx', '.dbf', '.cpg'];
const MAND_SHP_EXT = ['.shp', '.prj', '.shx', '.dbf'];
const ACCEPT_EXT = NON_SHP_EXT.concat(SHP_EXT);

const iff = (condition: any, trueRet: any, falseRet: any = '') => {
  if (condition === true) {
    if (typeof trueRet === 'function') {
      return trueRet();
    }
    return trueRet;
  } else {
    if (typeof falseRet === 'function') {
      return falseRet();
    }
    return falseRet;
  }
};

export const getFileName = (fileFullName = '') => {
  const arr = fileFullName.split('.');
  return arr[0];
};

export const getExtension = (fileFullName = '', dot = true) => {
  const arr = fileFullName.split('.');
  const ext = `${arr[arr.length - 1].toLowerCase()}`;
  if (dot === false) {
    return ext;
  }
  return `.${ext}`;
};

const { Option } = Select;
const ProjectionDD = (props: any) => {
  const onChange = (value: any) => {
    const projection = [];
    projection[props.filename] = value;
    props.setProjectionType(Object.assign({}, props.projectionType, projection));
  };
  const defaultValue = (props.projectionType && props.filename in props.projectionType) ?
  props.projectionType[props.filename] : props.defaultProjection;
  return (
    <Select
      dropdownClassName="select"
      defaultValue={defaultValue}
      onChange={onChange}
      dropdownStyle={{
        zIndex: 999999999,
      }}
    >
      <Option key="5842" value="5842">{props.labels.AMERICA_5842}</Option>
      <Option key="2154" value="2154">{props.labels.LAMBERT_93}</Option>
      <Option key="WGS84" value="WGS84">{props.labels.GCS_WGS_1984}</Option>
    </Select>
  );
};

const DeleteConfirmPopup = (props: any) => {
  const onConfirmDelete = () => {
    const files = props.fileList.filter((f: any) => {
      return !props.file.find((c: any) => {
        return c.name === f.name;
      });
    });
    props.setFileList(files);
  };
  return (
    <div className="filelist-icon-container">
      {
      iff(
        props.hideDeleteConfirmPopup,
        (
          <Tooltip title={props.labels.Delete_file} zIndex={99999}>
            <div className="delete-icon" onClick={onConfirmDelete}>
              {props.deleteIcon ? props.deleteIcon : <DeleteOutlined />}
            </div>
          </Tooltip>
        ),
        (
          <ConfirmPopup onConfirm={onConfirmDelete}>
            <Tooltip title={props.labels.Delete_file}>
              <div className="delete-icon">
                {props.deleteIcon ? props.deleteIcon : <DeleteOutlined />}
              </div>
            </Tooltip>
          </ConfirmPopup>
         ),
      )
      }
    </div>
  );
};

const validateFile = (files: any, file: any) => {
  let fileListArray = [...files];
  let isDuplicate = false;
  fileListArray = fileListArray.map((f) =>
    iff(
      f.name === file.name,
      () => {
        isDuplicate = true;
        return file;
      },
      f,
    ),
  );
  if (!isDuplicate) {
    fileListArray.push(file);
  }
  return fileListArray;
};

const GetMissingPrjFileWarningContent = (props: any) => {
  if (!props.providedExt.includes('.prj')) {
    return (
      <div className="filelist-icon-container">
        <Tooltip title={props.labels.proj_file_missing_warning}>
          <div className="warning_icon">
            <ProjFileMissingWarning />
          </div>
        </Tooltip>
      </div>
    );
  } else {
    return null;
  }
};

const GetMissingPrjFileWarningWithPrjDropDown = (props: any) => {
  const [providedExt, setProvidedExt] = useState<any>([]);

  useEffect(() => {
    const temp: any = [];
    if (getExtension(props.file[0].name) === '.zip') {
      JSZip.loadAsync(props.file[0]).then((zip) => {
        zip.forEach((relativePath, zipEntry) => {
          temp.push(getExtension(zipEntry.name));
        });
        setProvidedExt(temp);
      }).catch((error) => {
        if (error) {
          setProvidedExt(['.prj']);
        }
      });
    } else if (props.file.find((f: any) => SHP_EXT.includes(getExtension(f.name)))) {
      for (const val of props.file) {
        temp.push(getExtension(val.name));
      }
      setProvidedExt(temp);
    } else {
      setProvidedExt(['.prj']); // for other files dont display warning
    }
  }, [props.file]);

  return (
    <>
      <GetMissingPrjFileWarningContent
        labels={props.labels}
        providedExt={providedExt}
      />
      <div className="projection-dd-container">
        {props.validationCheck[props.i] &&
          (getExtension(props.file[0].name) === '.zip' ||
            props.file.find((f: any) =>
              SHP_EXT.includes(getExtension(f.name)),
            )) && !providedExt.includes('.prj') && (
            <ProjectionDD
              projectionType={props.projectionType}
              setProjectionType={props.setProjectionType}
              defaultProjection={props.defaultProjection}
              filename={props.file[0].name}
              labels={props.labels}
            />
          )}
      </div>
    </>
  );
};

const RenderItem = (props: any) => {
  const file = props.newFields[props.i];
  return (
    <List.Item key={props.fieldIndex} style={{ background: iff(props.fieldIndex % 2 !== 0, '#F5F8FA') }}>
      <List.Item.Meta
        avatar={
          (
            <div
              style={{
                height: '12px',
                width: '12px',
                backgroundColor: iff(
                  ACCEPT_EXT.indexOf(getExtension(file[0] && file[0].name)) <
                  0 || !props.validationCheck[props.i],
                  '#EB4B4B',
                  '#19A04B',
                ),
                borderRadius: '50%',
              }}
            />
          )
        }
        title={iff(
          ACCEPT_EXT.indexOf(getExtension(props.i)) < 0,
          getFileName(props.i),
          props.i,
        )}
        description={(
          <>
            <div className="file-ext">
              {file.map((f: any, index: any) => (
                <>
                  <span key={index} style={{ marginRight: '6px' }}>{getExtension(f.name)}</span>
                  {iff(
                    ACCEPT_EXT.indexOf(getExtension(f.name)) < 0,
                    (
                      <div style={{ color: '#EB4B4B', fontSize: '12px' }}>
                        {props.labels.Invalid_file_format}
                      </div>
                    ),
                    (
                      <>
                        {iff(
                          file.length - 1 === index,
                          (
                            <div>
                              {!props.validationCheck[props.i] && (
                                <div className="shapefileset-warn">
                                  {`${props.labels.Uploaded_file_must_be_either_of} ${NON_SHP_EXT.toString()} or a shapefile set (at least ${MAND_SHP_EXT.toString()})`}
                                </div>
                              )}
                            </div>
                          ),
                        )}
                      </>
                    ),
                  )}
                </>
              ))}
            </div>
          </>
        )}
      />
      <GetMissingPrjFileWarningWithPrjDropDown file={file} labels={props.labels} {...props} />
      <DeleteConfirmPopup
        fileList={props.fileList}
        file={file}
        setFileList={props.setFileList}
        labels={props.labels}
        deleteIcon={props.deleteIcon}
        hideDeleteConfirmPopup={props.hideDeleteConfirmPopup}
      />
    </List.Item>
  );
};

export const GeojsonInput = (props: any) => {
  useEffect(() => {
    tempFileList = props.fileList;
  }, [props.fileList]);

  const uploadProps = {
    multiple: true,
    accept: ACCEPT_EXT.toString(),
    showUploadList: false,
    name: 'file',
    beforeUpload: (file: any) => {
      let fileListArray = [...tempFileList];
      fileListArray = validateFile(fileListArray, file);
      tempFileList = fileListArray;
      props.setFileList(tempFileList);
      return false;
    },
    fileList: tempFileList,
  };

  const customRequest = (params: { file: any, onSuccess: any }) => {
    setTimeout(() => {
      params.onSuccess(props.labels.ok);
    }, 0);
  };

  const RenderList = (i: any, fieldIndex: any) => {
    return (
      <RenderItem
        i={i}
        key={i}
        fieldIndex={fieldIndex}
        validationCheck={props.validationCheck}
        setFileList={props.setFileList}
        fileList={tempFileList}
        {...props}
      />
    );
  };

  return (
      <>
        <div className="syt-dragger">
          <Dragger
            {...uploadProps}
            customRequest={customRequest}
            id="uploadDragger"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined style={{ color: '#0071CD' }} />
            </p>
            <p className="ant-upload-text dragging-message">
              {props.labels.Click_or_drag_file_to_this_area_to_upload}
            </p>
            <p className="supported-files-name">{`${props.labels.Supports}: JSON, GEOJSON, KML, ZIP, TOPOJSON, WKT`}</p>
          </Dragger>
        </div>
        <div className="filelist-title">{props.labels.Files}</div>
        <div id="files-container">
        <List
          dataSource={Object.keys(props.newFields)}
          split={false}
          locale={{
            emptyText: <Empty type="noFileUploaded" description={props.labels.No_files} />,
          }}
          renderItem={RenderList}
        />
        </div>
      </>
  );
};

GeojsonInput.propTypes = {
  setProjectionType: PropTypes.func.isRequired,
  projectionType: PropTypes.object.isRequired,
  defaultProjection: PropTypes.string.isRequired,
  setIsValid: PropTypes.func.isRequired,
  setNewFields: PropTypes.func.isRequired,
  newFields: PropTypes.object.isRequired,
  setGeojsonError: PropTypes.func.isRequired,
  setValidationCheck: PropTypes.func.isRequired,
  validationCheck: PropTypes.object.isRequired,
  setFileList: PropTypes.func.isRequired,
  fileList: PropTypes.array.isRequired,
  labels: PropTypes.object.isRequired,
};
