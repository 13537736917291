import React, { ErrorInfo } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`;
const Label = styled.h1`
  display: inline-block;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 46px;
  color: rgb(46, 51, 125);
`;

class ErrorBoundary extends React.Component<
  {},
  { hasError: boolean; error: any; info: any }
> {
  public static getDerivedStateFromError() {
    return { hasError: true };
  }
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null, info: null };
  }
  public componentDidCatch(error: Error, info: ErrorInfo) {
    console.error(error, info);
    this.setState({ hasError: true, error, info });
  }
  public render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Label>Something went wrong</Label>
          <p>{"Please try after some time"}</p>
        </Container>
      )
    }
    return this.props.children;
  }
}

export { ErrorBoundary };
