import { Collapse as AntCollapse } from 'antd';
import { CollapseProps } from 'antd/lib/collapse';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import React, { FC, ReactElement } from 'react';

import { AntdConfigProvider } from '../../config/AntdConfigProvider';

interface ICollapseProps extends FC<CollapseProps> {
  Panel: typeof CollapsePanel;
}

const Collapse: ICollapseProps = (props): ReactElement => {
  return (
    <AntdConfigProvider>
        <AntCollapse {...props}/>
    </AntdConfigProvider>
  );
};

Collapse.Panel = CollapsePanel;

export default Collapse;
