import React, { useRef } from 'react';
import { Modal, useModal } from 'react-morphing-modal';

const Button = (props: any) => {
  const btnRef = useRef(null);
  function handleClick() {
    props.openModal(btnRef);
  }

  return (
    <button ref={btnRef} onClick={handleClick} className="fullscreenCompBtn">
      <svg viewBox="0 0 1024 1024" className="icon-fullscreen">
        <path
          d="M959.688 920.068l0.31-176c0.040-22.094-17.84-40.032-39.93-40.070-22.092-0.040-40.032 17.838-40.070 39.93l-0.142 79.672-235.734-235.732c-15.622-15.622-40.948-15.622-56.57 0s-15.622 40.948 0 56.568l235.442 235.442-78.946-0.1c-22.092-0.028-40.022 17.86-40.050 39.952-0.014 11.064 4.464 21.084 11.714 28.334 7.228 7.224 17.208 11.702 28.236 11.714l175.688 0.22c22.086 0.028 40.014-17.846 40.052-39.93zM920.178 64.228l-176-0.308c-22.094-0.040-40.032 17.84-40.070 39.93-0.040 22.092 17.838 40.032 39.93 40.070l79.672 0.14-235.732 235.734c-15.622 15.622-15.622 40.948 0 56.568s40.948 15.622 56.568 0l235.442-235.442-0.1 78.946c-0.028 22.092 17.86 40.022 39.952 40.050 11.064 0.014 21.084-4.464 28.334-11.714 7.224-7.228 11.702-17.208 11.714-28.236l0.22-175.688c0.026-22.082-17.846-40.010-39.93-40.050zM64.236 103.742l-0.308 176c-0.040 22.094 17.84 40.032 39.93 40.070 22.092 0.040 40.032-17.84 40.070-39.93l0.14-79.672 235.734 235.73c15.622 15.622 40.948 15.622 56.568 0s15.622-40.946 0-56.566l-235.442-235.442 78.946 0.098c22.092 0.028 40.022-17.86 40.050-39.95 0.014-11.066-4.464-21.086-11.714-28.336-7.228-7.222-17.208-11.7-28.236-11.714l-175.688-0.218c-22.084-0.026-40.012 17.844-40.050 39.93zM103.748 959.766l176 0.308c22.094 0.040 40.032-17.84 40.070-39.93 0.040-22.092-17.84-40.032-39.93-40.070l-79.672-0.14 235.73-235.734c15.622-15.622 15.622-40.948 0-56.568s-40.946-15.622-56.566 0l-235.442 235.442 0.098-78.946c0.028-22.092-17.86-40.022-39.95-40.050-11.066-0.014-21.086 4.464-28.336 11.714-7.222 7.228-11.7 17.208-11.714 28.236l-0.218 175.688c-0.026 22.082 17.844 40.010 39.93 40.050z"
          className="css-kqzqgg"
        />
      </svg>
    </button>
  );
};

const FullScreen = (props: any) => {
  const { modalProps, open } = useModal();

  return (
    <div>
      <Button openModal={open} />
      <Modal {...modalProps}>
        <div className="fullScreenComp">
          {props.componentToRenderFullscreen}
        </div>
      </Modal>
    </div>
  );
};

export default FullScreen;
