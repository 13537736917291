import { Dropdown as AntDropdown } from 'antd';
import { DropdownButtonProps } from 'antd/lib/dropdown';
import React from 'react';
import { AntdConfigProvider } from '../../config/AntdConfigProvider';

const DropdownButton: React.FC<DropdownButtonProps> = (props) => {
  return (
    <AntdConfigProvider>
      <AntDropdown.Button {...props} />
    </AntdConfigProvider>
  );
};

export default DropdownButton;
